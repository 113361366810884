import React from 'react'
import bgImage from '../assets/images/monstrade-forex-account-banner.png'

export const MonstradeForexTradingAccount = () => {
    return (
        <div className=' xl:h-[76.1vw] xl:max-h-[1096px] relative mx-auto' 
            style={{
                backgroundImage: `url("${bgImage}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: '-1'
            }}
        >
            {/* <div className='absolute z-[-1]' >
                <img className='xl:block hidden' src={bgImage} alt="" />
            </div> */}
            <div className='container-max mx-auto'>
                <div className='p-16 sm:block hidden' >
                    <h3 className='text-white text-4xl text-center' >Monstrade Forex Trading Accounts</h3>
                </div>
                <div className='grid md:grid-cols-2 gap-10 pt-14'>
                    <div className='rounded-xl md:pl-20'>
                        <div className='bg-monstrade-blue text-white text-center rounded-t-2xl py-4'>
                            <p className='text-xl' >Account</p>
                            <p className='font-bold text-2xl' >Prime</p>
                            <p className='text-xl' >MT5</p>
                        </div>
                        <div className='bg-white rounded-b-2xl px-4 text-lg'>
                            <div>
                                <div className='border-b-2 py-3' >
                                    <p>Spreads</p>
                                    <p className='font-bold' >From 0.8 pips</p>
                                </div>
                                <div className='border-b-2 py-3'>
                                    <p>Commission</p>
                                    <p className='font-bold'>Zero</p>
                                </div>
                                <div className='border-b-2 py-3'>
                                    <p>Min. Deposit</p>
                                    <p className='font-bold'>$2500 USD or equivalent</p>
                                </div>
                                <div className='border-b-2 py-3'>
                                    <p>Max. Leverage</p>
                                    <p className='font-bold'>500:1</p>
                                </div>
                            </div>
                            <div className='h-[70px] flex' >
                                <div className='m-auto pt-2' >
                                    <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-white border-2 
                                                        border-monstrade-blue 
                                                        text-monstrade-blue
                                                        hover:bg-monstrade-blue hover:text-white
                                                        py-3 px-5 rounded-md'>START TRADING NOW</a>
                                </div>
                            </div>
                            <div className='pb-10' >
                                <div className='text-center' >
                                    <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='text-monstrade-blue decoration-solid underline' >Try a Free Demo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='rounded-xl md:pr-20'>
                        <div className='bg-[#A7A7A7] text-white text-center rounded-t-2xl py-4'>
                            <p className='text-xl' >Account</p>
                            <p className='font-bold text-2xl' >ECN</p>
                            <p className='text-xl' >MT5</p>
                        </div>
                        <div className='bg-white rounded-b-2xl px-4 text-lg'>
                            <div>
                                <div className='border-b-2 py-3' >
                                    <p>Spreads</p>
                                    <p className='font-bold' >From 0.6 pips</p>
                                </div>
                                <div className='border-b-2 py-3'>
                                    <p>Commission</p>
                                    <p className='font-bold'>Zero</p>
                                </div>
                                <div className='border-b-2 py-3'>
                                    <p>Min. Deposit</p>
                                    <p className='font-bold'>$10000 USD or equivalent</p>
                                </div>
                                <div className='border-b-2 py-3'>
                                    <p>Max. Leverage</p>
                                    <p className='font-bold'>500:1</p>
                                </div>
                            </div>
                            <div className='h-[70px] flex' >
                                <div className='m-auto pt-2' >
                                    <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-white border-2 
                                                        border-monstrade-blue 
                                                        text-monstrade-blue
                                                        hover:bg-monstrade-blue hover:text-white
                                                        py-3 px-5 rounded-md'>START TRADING NOW</a>
                                </div>
                            </div>
                            <div className='pb-6' >
                                <div className='text-center' >
                                    <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='text-monstrade-blue decoration-solid underline ' >Try a Free Demo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}