import React from 'react'
import { BannerContainer } from '../components/Containers/BannerContainer'
import bgImage from '../assets/images/banners/x-mas-banner.png'
import imgM1 from '../assets/images/x-mas-banner-mobile.png'
import { XMasCountdown } from '../components/XMasCountdown'
import { XMasCheckTicket } from '../components/XMasCheckTicket'
import { XMasRewards } from '../components/XMasRewards'
import { XMasStepToJoin } from '../components/XMasStepToJoin'
import Video from '../assets/videos/xmas-monstrade-video.mp4'
import { ToastContainer, toast } from 'react-toastify';
import newYear from '../assets/images/newYear_bg.png'
import ornaments from '../assets/images/ornaments.png'
import ornamentsMobile from '../assets/images/ornaments_mobile.png'
import ornaments2 from '../assets/images/ornaments2.png'
import ornaments2Mobile from '../assets/images/ornaments2_mobile.png'
import ornaments3 from '../assets/images/ornaments3.png'
import ornaments4 from '../assets/images/ornaments4.png'
import ChristmasBanner from '../assets/images/christmas-banner.png'
import { Link } from 'react-router-dom'

export const ChristmasDraw = () => {

  // const landingOptions = {
  //   image: newYear,
  //   mobileImage: newYear,
  //   container: {
  //     width: 'md:w-2/4 w-full',
  //     padding: 'py-20',
  //     margin: 'md:ml-0 m-auto'
  //   },
  //   header: {
  //     text: 'The Remaining Time For The Big New Year Draw',
  //     size: 'text-xl max-w-[340px] lg:block hidden ',
  //     color: 'text-white',
  //     align: "text-left"
  //   },
  //   header2: {
  //     text: 'Start Trading to win the Christmas Rewards',
  //     size: 'text-5xl',
  //     color: 'text-white',
  //     align: "md:text-left text-center lg:mr-20"
  //   },
  //   subHeader: {
  //     text: "Start investing now to become a ticket holder for the grand New Year's Eve draw!",
  //     size: 'text-2xl',
  //     color: 'text-white',
  //     align: "md:text-left text-center"
  //   },
  //   button: {
  //     bgcolor: "xmas-button",
  //     textcolor: "text-[#DC000D] font-bold shadow-lg md:ml-0 m-auto",
  //     text: "Query Ticket",
  //     padding: "pt-4 flex",
  //     href: "#terms"
  //   }
  // }

  const scrollToSection = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      const headerOffset = 100; 
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }

  return (
    <>
      <XMasCountdown />
      {/* <BannerContainer options={landingOptions} /> */}
        <div class="relative py-20 z-50 w-full bg-gradient-to-r from-[#8E1A1A] to-[#4F0303]">
          <div className="absolute top-0 right-0 z-10 lg:block hidden">
            <img src={ornaments} alt="" />
          </div>
          <div className="absolute top-0 right-0 z-10 lg:hidden block">
            <img src={ornamentsMobile} alt="" />
          </div>
          <div className="absolute top-0 left-0 transform z-10 lg:block hidden">
            <img src={ornaments3} alt="" />
          </div>
          <div className="absolute top-0 left-0 transform z-10 lg:hidden block">
            <img src={ornaments2Mobile} alt="" />
          </div>
         
          <div className="absolute bottom-0 right-0 transform z-10 lg:block hidden ">
            <img src={ornaments2} alt="" />
          </div>
          <div className="absolute bottom-0 left-0  transform  rotate-0 z-10 lg:block hidden">
            <img src={ornaments4} alt="" />
          </div>
          <div className='flex xl:flex-row flex-col-reverse justify-center items-center lg:px-32 px-5 gap-20 z-30'>
            <div className='xl:w-[525px] flex flex-col gap-3 xl:text-left text-center'>
              <h4 className='text-white uppercase'>The Remaining Time For The Big New Year Draw</h4>
              <h3 className='text-white text-4xl font-semibold'>Start to trade to win the Christmas Rewards</h3>
              <p className='text-white text-xl'>Start investing now to participate in the 2025 Big New Year's Lottery and win New Year's tickets.</p>
              <div className='relative z-30'>
                <button
                  onClick={() => scrollToSection('terms')}
                  className='text-[#8E1919] text-xl bg-white p-2 rounded-lg font-bold'>Browse the Campaign Terms</button>
              </div>
            </div>
            <div>
              <img src={ChristmasBanner} alt="" className='w-full' />
            </div>
          </div>
        </div>


      <XMasCheckTicket />
      {/* <XMasRewards/> */}
      <div className=' max-w-[1920px] mx-auto'>
        <video autostart autoPlay muted loop src={Video} type="video/mp4" />
      </div>
      <XMasStepToJoin />
    </>
  )
}
