import React,{useState,useEffect} from 'react'
import bgImage from '../assets/images/start-trading-img.png'
import { RegisterForm } from '../components/Forms/RegisterForm';
import styles from './StartTrading.module.css'
export const StartTrading = () => {
    const [isLoaded,setIsLoaded] = useState(false)
    useEffect(() => {
        
    },[isLoaded])
    let img = new Image();
    img.src = bgImage
    console.log(img.height)
  return (
    <div className={` relative h-auto bg-no-repeat bg-cover`} style={{ backgroundImage: `url("${bgImage}")`}}>
        <div className='container-max mx-auto'>
            <div className='grid md:grid-cols-2 sm:py-20 py-10 gap-10 sm:text-left text-center'>
                <div>
                    <div className='mb-7' > <h3 className='text-4xl text-white' > Start Trading in Minutes</h3> </div>
                    <div className='text-white text-lg text-left' >
                        <ul className={styles.sul} >
                            <li>Access 10,000+ financial instruments</li>
                            <li>Auto open & close positions</li>
                            <li>News & economic calendar</li>
                            <li>Technical indicators & charts</li>
                            <li>Many more tools included</li>
                        </ul>
                    </div>
                </div>
                <div className='flex md:justify-end justify-center' >
                    <div className='ld:w-3/4 w-full' >
                        <RegisterForm />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
