import React from "react";
//packages
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
//component
import { TwitterProfileTimeLine } from "../components/Containers/TwitterProfileTimeLine";
import ForexNewsCard from "../components/Cards/ForexNewsCard";
//content
import { currencyList } from '../features/content/currencyList'
import { useEffect } from "react";
import { useState } from "react";

const ContentForexNews = () => {
    const [apiData, setApiData] = useState([]);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [news, setNews] = useState([]);
    const currencies = currencyList();
    const options = Object.keys(currencies).map((currency) => {
        return { value: currency, label: `${currencies[currency].code} - ${currencies[currency].name}` }
    })
    const [selectedOptions, setSelectedOptions] = useState([options[0]])

    //selected option changes
    const changeHandler = (e) => {
        let diff = []
        let removed = []
        if (e.length > 0) {
            diff = e.filter((item) => { return !selectedOptions.includes(item) ? 1 : 0; });
            removed = selectedOptions.filter((item) => { return !e.includes(item) ? 1 : 0; });
        }

        if (diff.length > 0) {
            diff.forEach(element => {
                fetchNews(element);
            });
            setSelectedOptions(e);
        }

        if (removed.length > 0) {
            let cleared = news;
            removed.forEach((item) => {
                cleared = cleared.filter((newsItem) => { return newsItem.code !== item.value ? 1 : 0; })
            })
            setNews(cleared);
        }

    }

    //api call func
    const fetchNews = async (currency) => {
        try {
            setIsApiLoading(true);
            const response = await fetch(`/finage/news/forex/${currency.value}?apikey=API_KEY${process.env.REACT_APP_FINAGE_API_KEY}&limit=12`,
            {   method: 'GET',
                headers : {
                "Content-Type": "application/json",
                "Origin" : "application/json",
                "Access-Control-Allow-Origin" : "*",
                "Access-Control-Allow-Headers" : "Content-Type"
               }})
            let data = await response.json()
            data.news = data.news.map((d) => {
                d["code"] = currency.value;
                return d;
            })
            setApiData(data.news);
            setIsApiLoading(false);   
        } catch (error) {
            console.log(error)
        }
    }

    //on load api call
    useEffect(() => {
        fetchNews(selectedOptions[0])

    }, [])

    //assign to content
    useEffect(() => {
        if (apiData.length > 0) {
            setNews(current => [...current, ...apiData])
        }
    }, [apiData])

    return (
        <>
            <div className="" >
                <div className="container-max mx-auto">
                    <div className="grid sm:grid-cols-6 py-10" >
                        <div className="grid mr-4 auto-rows-max sm:text-left text-center
                                        lg:col-span-4 md:col-span-3 sm:col-span-3 col-span-6 
                                        lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-3">
                            <div className="col-span-full" >
                                <Select
                                    defaultValue={[options[0]]}
                                    options={options}
                                    isSearchable={true}
                                    isMulti
                                    onChange={changeHandler}
                                    isLoading={isApiLoading} />
                            </div>
                            {isApiLoading &&
                                <>
                                    <div>
                                        <Skeleton height={183} borderRadius={10} />
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton height={60} borderRadius={5} />
                                        <Skeleton height={90} borderRadius={5} />
                                    </div>
                                    <div>
                                        <Skeleton height={183} borderRadius={10} />
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton height={60} borderRadius={5} />
                                        <Skeleton height={90} borderRadius={5} />
                                    </div>
                                    <div>
                                        <Skeleton height={183} borderRadius={10} />
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton height={60} borderRadius={5} />
                                        <Skeleton height={90} borderRadius={5} />
                                    </div>
                                </>
                            }
                            {news.sort((a, b) => { return new Date(b.date) - new Date(a.date) }).map((item, index) => {
                                return (
                                    <ForexNewsCard key={index} item={item} />
                                )
                            })}
                        </div>
                        <div className="lg:col-span-2 md:col-span-3 col-span-6 lg:ml-4 w-100">
                            <TwitterProfileTimeLine />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContentForexNews;