import React from "react";
import TopBanner from "../components/TopBanner";
import Button from "../components/Button"
import TextArea from "../components/TextArea";
import EnergiesCard from "../components/Cards/EnergiesCard"
import PhoneMockup from '../components/PhoneMockup'
import AvailableAccountTypes from "../containers/AvailableAccountTypes";
import Security from "../components/Security";
import EnergiesImg from "../assets/images/energies_img.png"
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import WalletIco from "../assets/icons/wallet2.svg"
import IncreaseIco from "../assets/icons/increase.svg"
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";
import { BannerContainerV2 } from "../components/Containers/BannerContainerV2";

const mainTopic = "Energies"
const subText = "Invest in ETFs and CFDs on energy commodities, such as heating oil, oil, natural gas, and ethanol."

const topic1 = "Leverage value is up to 1:100"
const topic2 = "Spreads from 0 pips"
const topic3 = "Minimum deposit is 100 USD"
const topic4 = "Start trading with account"

const text1 = "Trade energy commodities with the leverage value up to 1:100."
const text2 = "Trade opportunity starting at 0 pips"
const text3 = "You can start trading with as few as 100 USD "
const text4 = "Energy commodities are an good choice for those, who prefer intraday trading"

const pageName = "Energies"

const landingOptions = {
    image: EnergiesImg,
    imageGradient: 'linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5))',
    mainZindex: 'z-[-1]',
    header: {
        text: 'Energies',
        size: 'text-[40px] leading-[46px]',
    },
    subHeader: {
        text: "Invest in ETFs and CFDs on energy commodities, such as heating oil, oil, natural gas, and ethanol.",
        align: "text-center",
        size: "text-3xl	",
    }
}
const cards = [
    { header: 'Leverage value is up to 1:100', description: '', icon: BalanceIco, link: '' },
    { header: 'Spreads from 0 pips', description: '', icon: ForexIco, link: '' },
    { header: 'Minimum deposit is 100 USD', description: '', icon: WalletIco, link: '' },
    { header: 'Start trading with account', description: '', icon: IncreaseIco, link: '' },
]

const textAreaTitle = "What is energy commodities and raw materials trading?"
const textAreaContent = "Trading energy commodities is very popular around the world and very promising for traders, who prefer intraday trading and want to diversify their investment portfolio. Some of most popular energy commodities are heating oil,oil, natural gas, and ethanol.<br/><br/> The easiest way to trade energies is to trade CFDs. Traders, who invest in CFDs on energy commodities, do not own energies themselves, but have an opportunity to receive profit thanks to price fluctuations on the market. Monstrade offers its clients to trade the most popular brands of oil, Brent and WTI."

const Energies = () => {
    return (
        <>
            {/* <TopBanner firstIcon={BalanceIco} secondIcon={ForexIco} thirdIcon={WalletIco} forthIcon={IncreaseIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} topic4={topic4} text1={text1} text2={text2} text3={text3} text4={text4} bgimage={EnergiesImg} is4Columns={true} />  */}
            <BannerContainerV2 options={landingOptions}>
                <div className="" >
                    <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'md:grid-cols-4 grid-cols-2'} />
                </div>
            </BannerContainerV2>
            <div className="absolute left-1/2 -translate-x-1/2 -mt-3">
                <Button
                    content="TRADE INDICES"
                    color="bg-monstrade-button-green"
                    py="py-3"
                    px="lg:px-6 px-2"
                    isArrow={true}
                />
            </div>
            <TextArea textAreaTitle={textAreaTitle} textAreaContent={<div dangerouslySetInnerHTML={{ __html: textAreaContent }} />} />
            <EnergiesCard />
            <PhoneMockup
                pageName={pageName}
                title={"Monstrade trading platforms"}
                subtitle={"The most popular trading platforms in the industry"}
                desc={"You can reach out to the Monstrade from any devices to trade with competitive conditions. All you need to do that just choose a platform and start your investment in Energies."}
                linkHref={"/platforms/computer/mt5-for-pc"}
                linkText={"Download a platform and invest in forex"}
            />
            <AvailableAccountTypes />
            <Security />
        </>
    )
}

export default Energies;