import React from "react";
import TopBanner from "../components/TopBanner";
import Button from "../components/Button"
import Table from "../components/Table"
import PhoneMockup from '../components/PhoneMockup'
import AvailableAccountTypes from "../containers/AvailableAccountTypes";
import Faq from "../components/Faq"
import Security from "../components/Security";
import MetalsImg from "../assets/images/metals_img.png"
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import WalletIco from "../assets/icons/wallet2.svg"
// import { BannerContainer } from "../components/Containers/BannerContainer";
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";
import { BannerContainerV2 } from "../components/Containers/BannerContainerV2";

const pageName = "Metals"

const landingOptions = {
    image: MetalsImg,
    imageGradient: 'linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5))',
    mainZindex: 'z-[-1]',
    header: {
        text: 'Start Investing in Metals',
        size: 'text-[40px] leading-[46px]',
    },
    subHeader: {
        text: "Trade Gold (XAU/USD), Silver (XAG/USD), and ETFs on gold, palladium, platinum, and silver.",
        align: "text-center",
        size: "text-3xl"
    }
}
const cards = [
    { header: 'Leverage up to 1:100', description: '', icon: BalanceIco, link: '' },
    { header: 'Low spreads', description: '', icon: ForexIco, link: '' },
    { header: 'Start trading with 100 USD', description: '', icon: WalletIco, link: '' },
]

const faqTitle1 = "What is metal trading?"
const faqText1 = "Precious metals have always been important elements of the financial system. One of the most valuable assets is Gold. Investing in metals allows traders to hedge risks of the USD “weakness” and possible political turmoil.<br/><br/>Monstrade provides reliable access to trading XAU/USD and XAG/USD pairs through its own terminal and MetaTrader 5 terminal. Using Monstrade, you have an opportunity to invest in more than 10 ETFs on such metals as palladium, aluminum, copper, nickel and platinum."

const MetalsPage = () => {
    return (
        <>
            {/* <TopBanner firstIcon={BalanceIco} secondIcon={ForexIco} thirdIcon={WalletIco} mainTopic={mainTopic} subText={subText} topic1={topic1} topic2={topic2} topic3={topic3} text1={text1} text2={text2} text3={text3} bgimage={MetalsImg} imageGradient="linear-gradient(90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5))"/>  */}
            <BannerContainerV2 options={landingOptions}>
                <div className="" >
                    <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'grid-cols-3'} />
                </div>
            </BannerContainerV2>
            <div className="absolute left-1/2 -translate-x-1/2 -mt-3">
                <Button
                    content="TRADE METALS"
                    color="bg-monstrade-button-green"
                    py="py-3"
                    px="lg:px-6 px-2"
                    isArrow={true}
                />
            </div>
            {/* <Table/> */}
            <PhoneMockup
                pageName={pageName}
                title={"Choose a platform and invest in metals"}
                subtitle={"Convenient trading platforms"}
                desc={"You can reach out to the Monstrade from any devices to trade with competitive conditions. All you need to do that just choose a platform and start your investment in Metal."}
                linkHref={"/platforms/computer/mt5-for-pc"}
                linkText={"Download a platform and invest in forex"}
            />
            <AvailableAccountTypes />
            <Faq faqTitle1={faqTitle1} faqText1={<div dangerouslySetInnerHTML={{ __html: faqText1 }} />} isTwoItems={false} />
            <Security />
        </>
    )
}

export default MetalsPage;