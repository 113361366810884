import React from "react";
import image from "../assets/images/banners/economic-calender-banner.png"
import ContentEconomicCalender from "../containers/Content.EconomicCalender";
import EconomicCalenderContainer from "../containers/EconomicCalendar.container";
import { BannerContainerV2 } from '../components/Containers/BannerContainerV2'

const landingOptions = {
    image : image,
    imageGradient: "linear-gradient(90deg, rgba(20, 20, 20, 0.4), rgba(0, 0, 0, 0.1))",
    container : {
      width : 'w-3/4',
      padding : 'py-20'
    },
    header: {
      text : 'Economic Calendar',
      size: 'text-3xl'
    },
    subHeader: {
        text : "Economic events are a vital part of your trading plan and strategy. \
        With Monstrade’s economic calendar, you can look ahead to see upcoming \
        economic releases, reports, decisions and events that can potentially \
        affect your trading or increase your opportunities."
    },
    button: {
        bgcolor: "bg-monstrade-green",
        textcolor: "text-white",
        text: "Become a Partner",
        padding: "pt-4"
    }
  }

const EconomicCalender = () => {
    
    return (
        <>
            <BannerContainerV2 options={landingOptions}/>
            <EconomicCalenderContainer />
            <ContentEconomicCalender />
        </>
    )
}

export default EconomicCalender;