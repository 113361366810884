import React from "react";

const SpreadsAffect = () => {
    return (
        <div className="p-4" >
            <div className="container-max mx-auto">
                <div className="text-center" >
                    <div className="py-2">
                        <p className="text-3xl font-semibold" >Spreads Affect Your Trades</p>
                    </div>
                    <div>
                        <p className="text-lg" >
                            Successful traders make their trading decisions based on such changes. They always track changes in the spread. 
                            Spread size plays an important role in trading, especially for scalpers and intraday traders:
                        </p>
                    </div>
                    <div className="grid md:grid-cols-2" >
                        <div className="md:pr-12 md:border-r-2 py-6" >
                            <p className="font-bold leading-10 text-xl" >High Spreads</p>
                            <p className="text-lg">
                                When the difference between the bid and ask prices is higher than normal, 
                                it may indicate a period of either low liquidity or high market volatility. For example, 
                                non-major forex pairs tend to spread higher than major pairs.
                            </p>
                        </div>
                        <div className="md:pl-12">
                            <p className="font-bold leading-10 text-xl" >Low Spreads</p>
                            <p className="text-lg">
                                When the difference between the ask and bid prices is lower than usual, 
                                it may indicate a period of either high liquidity or low market volatility. 
                                For example, spreads tend to be lower during the major forex sessions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpreadsAffect;