import React from "react";
import ContentLeverage from "../containers/Content.Leverage";
import bgImage from "../assets/images/banners/leverage-banner.png";
import { BannerContainerV2 } from "../components/Containers/BannerContainerV2";

const landingOptions = {
    image : bgImage,
    container : {
      width : 'w-full',
      padding : 'py-20'
    },
    header: {
      text : 'LEVERAGE INFORMATION',
      size: 'text-3xl',
      align: "text-center"
    },
    subHeader: {
        text : "",
        align: "text-left"
    }
  }

const Leverage = () => {
    return (
        <>
            <BannerContainerV2 options={landingOptions}/>
            <ContentLeverage />
        </>
    )
}

export default Leverage