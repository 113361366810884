import React from 'react'
import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const BannerContainerV2 = ({ options, children }) => {
    const { height, width } = useWindowDimensions();
    let container = options.container ? options.container : {};
    return (
        <div className={` relative bg-no-repeat bg-cover bg-center ${options.mainZindex ? options.mainZindex : 'z-10'}`} style={{ backgroundImage: `url("${width > 768 || !options.mobileImage ? options.image : options.mobileImage}")` }}>
            {options.imageGradient && <div className='absolute h-full w-full z-20' style={{ background: `${options.imageGradient}` }}></div>}
            <div className='container-max py-12 m-auto flex'>
                <div className=
                    {`z-30 ${container.textAlign ? container.textAlign : 'text-center'}
                    ${container.width ? container.width : 'w-full'}
                    ${container.margin ? container.margin : 'm-auto'}
                    ${container.padding ? container.padding : 'padding-0'}`} >
                    <div className={`${container.marginY ? options.container.marginY : ''}`} >
                    {options.additionTop && <div className={options.additionTop.padding}>
                            <div className={`
                                    ${options.additionTop.bgcolor}
                                    ${options.additionTop.textcolor}
                                    ${options.additionTop.bordercolor ? `border-2 ${options.additionTop.bordercolor}` : ""}
                                    ${options.additionTop.align}`}>
                                <img className={options.additionTop.imageMx} src={options.additionTop.image} alt="" />
                            </div>
                    </div>}
                        {options.header &&
                            <div className='mb-6' >
                                <h1 className=
                                    {`${options.header.size ? options.header.size : 'text-2xl'} 
                    ${options.header.bold ? options.header.bold : 'font-bold'}
                    ${options.header.color ? options.header.color : 'text-white'}
                    ${options.header.align ? options.header.align : 'text-center'}`}>{options.header.text}</h1>
                            </div>}

                            {options.header2 &&
                            <div className='mt-[-20px] mb-6' >
                                <h1 className=
                                    {`${options.header2.size ? options.header2.size : 'text-2xl'} 
                    ${options.header2.bold ? options.header2.bold : 'font-bold'}
                    ${options.header2.color ? options.header2.color : 'text-white'}
                    ${options.header2.align ? options.header2.align : 'text-center'}`}>{options.header2.text}</h1>
                            </div>}
                        {options.subHeader &&
                            <div className={`${options.subHeader.margin ? options.subHeader.margin : 'mb-6'}`} >
                                <h2 className=
                                    {`${options.subHeader.size ? options.subHeader.size : 'text-normal'} 
                                    ${options.subHeader.bold ? options.subHeader.bold : 'font-normal'} 
                                    ${options.subHeader.color ? options.subHeader.color : 'text-white'}
                                    ${options.subHeader.align ? options.subHeader.align : 'text-center'}`}>{options.subHeader.text}
                                </h2>
                            </div>
                        }
                        {options.button &&
                            <div className={options.button.padding}>
                                <a href={options.button.href ? options.button.href : `https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard`} className={`
                                    ${options.button.bgcolor}
                                    ${options.button.textcolor}
                                    ${options.button.bordercolor ? `border-2 ${options.button.bordercolor}` : ""}
                                    ${options.button.hover ? `${options.button.hover}` : 'hover:bg-monstrade-blue'}
                                    ${options.button.textSize ? `${options.button.textSize}` : 'lg:text-2xl md:text-xl '}
                                    hover:text-white
                                    ease-in
                                    duration-150
                                    lg:py-3 lg:px-4 sm:px-2 py-2 px-2
                                    rounded-lg 
                                    font-semibold`}>
                                    {options.button.text}
                                </a>
                            </div>}
                        {options.button2 && <div className={options.button2.padding}>
                            <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className={`
                                    ${options.button2.bgcolor}
                                    ${options.button2.textcolor}
                                    ${options.button2.bordercolor ? `border-2 ${options.button2.bordercolor}` : ""}
                                    ${options.button2.textSize ? `${options.button2.textSize}` : 'lg:text-2xl md:text-xl '}
                                    hover:bg-monstrade-blue
                                    hover:text-white
                                    ease-in
                                    duration-150
                                    lg:py-4 lg:px-4 sm:px-2 py-2 px-2
                                    rounded-lg 
                                    font-semibold`}>
                                {options.button2.text}
                            </a>
                        </div>}
                        {options.addition && <div className={options.addition.padding}>
                            <div className={`
                                    ${options.addition.bgcolor}
                                    ${options.addition.textcolor}
                                    ${options.addition.bordercolor ? `border-2 ${options.addition.bordercolor}` : ""}
                                    ${options.addition.align}`}>
                                <img className={options.addition.imageMx} src={options.addition.image} alt="" />
                            </div>
                        </div>}
                        {children}
                    </div>

                </div>
            </div>
        </div>
    )
}
