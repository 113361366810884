import React from 'react'
import { BannerContainer } from '../components/Containers/BannerContainer'
import { SimpleCardListContainer } from '../components/ContainerLayout/SimpleCardListContainer'
//images
import image from '../assets/images/banners/platforms-banner.png'
import icon1 from '../assets/icons/platform-icon-1.svg'
import icon2 from '../assets/icons/platform-icon-2.svg'
import icon3 from '../assets/icons/platform-icon-3.svg'
import icon4 from '../assets/icons/platform-icon-4.svg'
import { PlatformDeviceContainer } from '../containers/PlatformDeviceContainer'
import { HowDoOpenAccount } from '../containers/HowDoOpenAccount'
import { WhyChoosePlatform } from '../containers/WhyChoosePlatform'
import { BannerContainerV2 } from '../components/Containers/BannerContainerV2'

export const Platforms = () => {

    const landingOptions = {
        image : image,
        container : {
          width : 'md:w-1/2',
          padding : 'py-44 ',
          margin: 'md:ml-0 m-auto'
        },
        header: {
          text : 'Trade on Monstrade’s MT5',
          size: 'text-4xl'
        },
        subHeader: {
          text : "You’re in for a treat with MetaQuotes multi-asset platform, built for online CFD trading and exchange instruments.",
          size: 'text-2xl',

        },
          
        button: {
            bgcolor: "bg-[#28B2FF]",
            hover: "hover:bg-[#0d5c86]",
            textcolor: "text-white",
            text: "Get Mt5",
            padding: "pt-4",
        }
      }


    const cards = [
        { 
            header: 'Flexible trade system', 
            description: 'A big and reliable online shop', 
            icon: icon1, 
            link: '' 
        },
        { 
            header: 'MetaTrader Market', 
            description: '<span class="md:block hidden">MetaTrader 5 features netting and hedging systems, Market Depth and segregtion of orders and transactions.</span> \
                          <span class="md:hidden block">Expert advisors and technical indicators</span>', 
            icon: icon2, 
            link: '' 
        },
        { 
            header: 'Algorithmic Trading', 
            description: 'Using MQL5, a specialized programming envioronmet, you can create, optimize and test trading robots by yourself.', 
            icon: icon3, 
            link: '' 
        },
        { 
            header: 'Virtual Hosting', 
            description: 'MetaTrader 5 may help you to rent a virtual hosting for 24/7 operation of trading robots and subscriptocon for trading signals.', 
            icon: icon4, 
            link: '' 
        }
    ]

  return (
    <div>
        <BannerContainerV2 options={landingOptions}/>
        <SimpleCardListContainer cards={cards} />
        <PlatformDeviceContainer />
        <HowDoOpenAccount />
        <WhyChoosePlatform />
    </div>
  )
}
