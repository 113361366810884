import React from 'react'

export const ForexSpreadsFAQ = () => {
    return (
        <div className='bg-[#EEEEEE] '>
            <div className='container-max mx-auto py-4'>
                <h3 className='text-3xl py-8 font-semibold text-center'>Forex Spreads - FAQ</h3>
                <div className='border-[2px] border-monstrade-blue rounded-xl' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse1"
                        aria-expanded="false"
                        aria-controls="collapse1">
                        How is Spread Calculated in the Forex Market?
                    </button>

                    <div className="collapse" id="collapse1">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        Investing in the forex markets involves trading one currency in exchange for another at a preset exchange rate. Therefore, currencies are quoted in terms of their price in another currency. The forex spread is the difference between the exchange rate that a forex broker sells a currency, and the rate at which the broker buys the currency.
                        </div>
                    </div>
                </div>
                <div className='border-[2px] border-monstrade-blue rounded-xl my-2' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapseExample">
                        What Does a Forex Spread Tell Traders?
                    </button>

                    <div className="collapse" id="collapse2">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        If you are currently holding a position and the spread widens dramatically, you may be stopped out of your position or receive a margin call. The only way to protect yourself during times of widening spreads is to limit the amount of leverage used in your account. It is also sometimes beneficial to hold onto a trade during times of spread-widening until the spread has narrowed.
                        </div>
                    </div>
                </div>
                <div className='border-[2px] border-monstrade-blue rounded-xl my-2' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapseExample">
                        How much is 10 pips worth?
                    </button>

                    <div className="collapse" id="collapse3">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        The pip value is $1. If you bought 10,000 euros against the dollar at 1.0801 and sold at 1.0811, you'd make a profit of 10 pips or $10. On the other hand, when the USD is the first of the pair (or the base currency), such as with the USD/CAD pair, the pip value also involves the exchange rate.
                        </div>
                    </div>
                </div>
                <div className='border-[2px] border-monstrade-blue rounded-xl my-2' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapseExample">
                        How does spread affect profit?
                    </button>

                    <div className="collapse" id="collapse4">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        On the other hand, a wider spread means there is a very large difference between the ask and bid price due to the market having low liquidity and high volatility. This is bound to impact the profit margin of a trader negatively since any trade initiated will be quite costly due to the wider spread.
                        </div>
                    </div>
                </div>
                <div className='border-[2px] border-monstrade-blue rounded-xl my-2 mb-10' >
                    <button className="inline-block px-6 py-5 bg-white w-full text-left
                                            font-bold 
                                            leading-tight 
                                            rounded-2xl
                                             focus:outline-none 
                                             focus:ring-0
                                             transition duration-150 ease-in-out"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapseExample">
                        What is low spread in forex?
                    </button>

                    <div className="collapse" id="collapse5">
                        <div className="block p-6 rounded-b-2xl shadow-lg bg-white visible">
                        Picking a Low Spread Forex Broker or Zero Spread Account is essential, especially for Forex trading and scalping. Paying less cuts trading costs in the long run, especially if you are an active trader.
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
