import React from 'react'
import { useState, useEffect } from 'react'
import Image1 from '../assets/images/x-mas-image1.png'
import DoubleDot from '../assets/images/double-dot.png'
import CountdownBg from '../assets/images/countdown-bg.png'

export const XMasCountdown = () => {
    const targetDate = new Date('January 1, 2025 00:00:00 GMT+00:00');
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [targetDate]);

    function calculateTimeLeft(targetDate) {
        const now = new Date().getTime();
        const difference = targetDate - now;

        if (difference <= 0) {
            return {
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00',
            };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
        const seconds = Math.floor((difference % (1000 * 60)) / 1000).toString().padStart(2, '0');

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    }

    return (
        <>
            <div className=" mx-auto z-0 lg:h-[753px] bg-[#24000b] relative bg-no-repeat" 
                style={{
                    backgroundImage: `url("${CountdownBg}")`,
                    backgroundSize: 'cover',
                }}>
                <div className='flex flex-col justify-center py-20'>
                    <p className='countdown-year outline-[#D32626]'>2025</p>
                    <p className='text-center text-white text-5xl max-w-[600px] pt-10 mx-auto font-normal'>
                        The Remaining Time For The Big New Year Draw
                    </p>
                    <div className='flex flex-wrap justify-center items-center gap-4 lg:gap-8 px-4 py-10'>
                        <div className='flex-1 max-w-[165px]'>
                            <div className='countdown-div h-[174px] mx-auto p-2'>
                                <div className='countdown-inner-div h-[156px]'>
                                    <p className='countdown-red outline-red-600 pt-2'>{timeLeft.days}</p>
                                </div>
                            </div>
                            <div><p className='text-center text-white'>DAYS</p></div>
                        </div>
                        
                        <div className='hidden lg:block'>
                            <img className='mx-auto' src={DoubleDot} alt="" />
                        </div>
                        
                        <div className='flex-1 max-w-[165px]'>
                            <div className='countdown-div h-[174px] mx-auto p-2'>
                                <div className='countdown-inner-div h-[156px]'>
                                    <p className='countdown-red outline-red-600 pt-2'>{timeLeft.hours}</p>
                                </div>
                            </div>
                            <div><p className='text-center text-white'>HOURS</p></div>
                        </div>
                        
                        <div className='hidden lg:block'>
                            <img className='mx-auto' src={DoubleDot} alt="" />
                        </div>
                        
                        <div className='flex-1 max-w-[165px]'>
                            <div className='countdown-div h-[174px] mx-auto p-2'>
                                <div className='countdown-inner-div h-[156px]'>
                                    <p className='countdown-red outline-red-600 pt-2'>{timeLeft.minutes}</p>
                                </div>
                            </div>
                            <div><p className='text-center text-white'>MINUTES</p></div>
                        </div>
                        
                        <div className='hidden lg:block'>
                            <img className='mx-auto' src={DoubleDot} alt="" />
                        </div>
                        
                        <div className='flex-1 max-w-[165px]'>
                            <div className='countdown-div h-[174px] mx-auto p-2'>
                                <div className='countdown-inner-div h-[156px]'>
                                    <p className='countdown-red outline-red-600 pt-2'>{timeLeft.seconds}</p>
                                </div>
                            </div>
                            <div><p className='text-center text-white'>SECONDS</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}