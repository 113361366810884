import React from 'react'

export const ContainerWithFlat = ({children, bgColor, display}) => {
    return (
        <div className={` relative ${bgColor}`}>
            <div className={`container-max m-auto h-full ${display? display : "flex"}`}>
                {children}
            </div>
        </div>
    )
  
}
