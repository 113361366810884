import React from 'react'
import { AccountTypeCompareCard } from '../components/Cards/AccountTypeCompareCard';
//images
import icon1 from '../assets/icons/personWithOneStar.svg';
import icon2 from '../assets/icons/account-double-start.svg';
import icon3 from '../assets/icons/account-third-start.svg';
import icon4 from '../assets/icons/account-king.svg';
import icon5 from '../assets/icons/account-circle.svg';
import icon6 from '../assets/icons/account-islamic.svg';

import bgImage from '../assets/images/compare-bg.png'

export const CompareAccountTypeContainer = () => {
    const cards = [
        {header: "DEMO", minDeposit : "100", icon: icon1, spread : '1.0', leverage : '100', features : [
            'Autochartist',
            'Trading signals',
            'Daily news update',
            'Market analysis (daily live-broadcast)',
            'Economic calendar'] },
        {header: "Cent", minDeposit : "100", icon: icon2, spread : '1.6', leverage : '100', features : [
            'Autochartist',
            'Trading signals',
            'Daily news update',
            'Market analysis (daily live-broadcast)',
            'Economic calendar']},
        {header: "Classic", minDeposit : "100" , icon: icon3, spread : '1.6', leverage : '300', features : [
            'Negative balance protection',
            'Autochartist',
            'Trading signals',
            'Daily news update',
            'Market analysis (daily live-broadcast)',
            'Economic calendar']},
        {header: "Prime", minDeposit : "2500", icon: icon4, spread : '0.8', leverage : '300', features : [
            'Expert Advisors (EA) trading tool',
            'Negative balance protection',
            'Autochartist',
            'Trading signals',
            'Daily news update',
            'Market analysis (daily live-broadcast)',
            'Economic calendar']},
        {header: "ECN", minDeposit : "10000", icon: icon5, spread : '0.6', leverage : '300', features : [
            'Expert Advisors (EA) trading tool',
            'Negative balance protection',
            'Autochartist',
            'Trading signals',
            'Daily news update',
            'Market analysis (daily live-broadcast)',
            'Economic calendar']},
        {header: "Islamic", minDeposit : "15000", icon: icon6, spread : '1.2', leverage : '100', features : [
            'Expert Advisors (EA) trading tool',
            'Negative balance protection',
            'Autochartist',
            'Trading signals',
            'Daily news update',
            'Market analysis (daily live-broadcast)',
            'Economic calendar'
        ]},
    ]
    return (
        <div className=' font-roboto py-10'
        style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
        }}
        >
            <div className='container-max mx-auto'>
                <div className=' mb-12 text-center' >
                    <h2 className='text-3xl text-white font-semibold' >Compare Our Account Types</h2>
                </div>
                <div className='lg:grid-cols-4 sm:grid-cols-2 grid gap-2 mb-10' >
                    {cards.map((item, index) => {
                        return (
                            <div className={`
                            mx-auto
                                            ${index === 4 ? 'lg:col-start-2 lg:col-end-3 lg:justify-self-end' : ''}
                                            ${index === 5 ? 'lg:col-start-3 lg:col-end-4 lg:justify-self-start' : ''}`} >
                                <AccountTypeCompareCard item={item} />
                            </div>
                        )
                    })}
                </div>
              
            </div>
        </div>
    )
}
