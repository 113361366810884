import React from "react";

const ContentEconomicCalender = () => {
    return (
        <>
            <div className="" >
                <div className="container-max m-auto">
                    <div className="py-12" >
                        <h2 className="text-monstrade-blue text-xl leading-10 font-semibold" >What is economic calendar?</h2>
                        <br />
                        <p >The economic calendar shows news and other important publications to be published during trading sessions. 
                            These are the main data that affect the prices of small and large markets. Hence, the economic calendar is 
                            one of the most important tools for real time analysis if you want to trade foreign exchange.
                        </p>
                        <br />
                        <h2 className="text-monstrade-blue text-xl leading-10 font-semibold">How to use the economic calendar?</h2>
                        <br />
                        <p >In addition to its unique importance for fundamental analysis and forecasting, the Forex live economic calendar 
                            acts as an indicator for news trading in the Forex market as mentioned above. If you are an exchange trader and 
                            want to get an advice on opening a position, the calendar can serve as a reliable source of information.
                            <br/>
                            <br/>
                            For this purpose, this is what you are looking for and it is proper for all investors.
                        </p>
                        <br />
                        <ol className="list-decimal list-inside" >
                            <li className="pb-4" >
                            A trader opens the economic calendar and notes the important macroeconomic news at the beginning of a new trading 
                            day (or the day before). The news marked with three exclamation marks on the calendar is very important.
                            </li>
                            <li className="pb-4" >
                            If you are experienced enough, they analyze the reaction of the main market players along with previous macroeconomic 
                            data and how this is reflected in the asset listing dynamics. It is also important to get the opinion of market experts 
                            and more experienced traders, and to seek general market comments and advice from independent experts on the internet, 
                            including the MonsTrade blog.
                            </li>
                            <li className="pb-4"  >
                            Immediately after its evaluation in the newsletter and economic calendar, the trader reconciles the published data with 
                            the forecast and makes a deposit decision based on the results. Please note that you should carefully review your 
                            investment goals under all circumstances.
                            </li>
                        </ol>
                        <br />
                        <ul className="list-disc font-extralight" >
                            <li className="pb-4" >
                            If the news is positive, i.e. its value is higher than the forecast median, it may be decided to buy the currency 
                            whose quotes are most sensitive to macroeconomic news content.
                            </li>
                            <li className="pb-4" >
                            If the news is negative, i.e. its value is lower than the forecast median, it may be decided to sell the currency 
                            whose quotes are most sensitive to macroeconomic news content.
                            </li>
                        </ul>
                        <br />
                        <p>
                        However, news trading is not the only way to use the economic calendar. Most of the time, the published news is great 
                        importance for both Forex trading and the world economy. There are events like changes in the level of discount rates 
                        made by central banks (regulators), or the release of statistics on inflation, the number of employees, etc. Such data, 
                        if published, is able to channel the quotes of Forex market assets, stock and commodity markets into long-term trends. 
                        Such manifestations occur directly or indirectly and are of interest to investors who can use the economic calendar 
                        for long-term forecasts and investments.
                        </p>
                        <br />
                        <h2 className="text-monstrade-blue text-xl leading-10 font-semibold" >What to consider when using Forex economic calendar?</h2>
                        <br />
                        <p>
                        The following data may be so essential when you explore the economic calendar:
                        </p>
                        <br />
                        <ul className="list-disc font-extralight" >
                            <li className="pb-4">
                                The time of publication according to the trader's location.
                            </li>
                            <li className="pb-4">
                            The source of the news (in which country the event took place and what is the broadcast source). 
                            For example, if one of the pairs you trade is USD/CAD, you should pay attention to Canadian and 
                            US economic statistics.
                            </li>
                            <li className="pb-4">
                            The significance of the news, ie its impact on the relevant asset. If the impact is low, the 
                            price of the relevant currency will probably not be affected. On the other hand, if important 
                            news is published, there will be high volatility in the financial asset.
                            </li>
                            <li className="pb-4">
                            The nature and style of the news will make it possible to judge the nature of this event, whether 
                            it's Mario Draghi's speech or US unemployment statistics.
                            </li>
                            <li className="pb-4">
                            Results and forecast statistics: historical data allows you to evaluate the evolution of events and 
                            compare them with the current market situation, and perhaps even give a different perspective on the 
                            initial investment.
                            </li>
                        </ul>
                        <br />
                        <p className="font-semibold" >
                            Note that reliance on such information should be somewhat limited and not be the only source of 
                            decision-making. Risks associated with foreign trading can never be reduced to zero, no 
                            matter what analysis method you use. Always keep in mind the amount you can afford to lose 
                            if you decide to open a trade according to news, and that Forex economic calendar contents 
                            do not constitute investment advice.
                        </p>
                    </div>
                    <div className="flex w-full text-white" >
                        <div className=" w-full py-2 flex" >
                            <a href="/about/contact-us/contact" className="text-center bg-monstrade-blue hover:bg-monstrade-green text-lg w-full h-full py-5 rounded-md" ><span className="font-bold">Need help?</span> Speak with us</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContentEconomicCalender;