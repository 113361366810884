import React from 'react'

export const HowDoOpenAccount = () => {
  return (
    <div className=' bg-mons-gray-1 md:block hidden' >
        <div className='container-max mx-auto'>
            <div className='text-center py-10' >
                <h3 className='text-3xl' > <span className='' >How Do I Open an Account on the</span><br/>MT5 with Monstrade?</h3>
            </div>
            <div className='grid md:grid-cols-2 grid-rows-1 gap-24 py-12'>
                <div>
                    <p className='mb-5' >New to MT5</p>
                    <ul className='text-sm leading-7 list-disc'>
                        <li>You can download and install MetaTrader 5 in a few simple steps.</li>
                        <li>Register and open a Live Trading Account or Demo Account</li>
                        <li>Login to Client Portal using credentials provided in the email</li>
                        <li>Download the appropriate version for your operating system</li>
                        <li>Install, login and start trading</li>
                    </ul>
                </div>
                <div>
                    <p className='mb-5' >Already Installed MT5</p>
                    <p className='text-sm leading-4 mb-5' >
                        If you have already installed the MT5 and would like to start trading 
                        with Monstrade, open a Live Account and login with your Monstrade Live 
                        Account Credentials.
                    </p>
                    <div className='grid grid-cols-2 gap-2 text-center'>
                            <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 text-white px-4 py-3 hover:text-white hover:bg-monstrade-green text-sm rounded' >OPEN AN ACCOUNT</a>
                            <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 text-white px-4 py-3 hover:text-white hover:bg-monstrade-green text-sm rounded' >REQUEST A DEMO</a>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
