import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

/**
 * Pagination Component
 * Displays a pagination control with page numbers and navigation arrows
 * 
 * @param {number} currentPage - The current active page
 * @param {number} totalPages - Total number of pages
 * @param {function} onPageChange - Callback function when page changes
 * 
 * Features:
 * - Shows first and last page always
 * - Displays ellipsis (...) when there are many pages
 * - Shows current page and adjacent pages
 * - Responsive design with hover effects
 */
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    /**
     * Calculate which page numbers should be visible
     * @returns {Array} Array of numbers and strings (for ellipsis)
     * 
     * Logic:
     * - If total pages <= 5, show all pages
     * - Always show first page
     * - Show ellipsis if there's a gap after first page
     * - Show current page and adjacent pages
     * - Show ellipsis if there's a gap before last page
     * - Always show last page if not already shown
     */
    const getVisiblePages = () => {
        const pages = [];
        // If 5 or fewer pages, show all
        if (totalPages <= 5) return Array.from({ length: totalPages }, (_, i) => i + 1);

        // Always show first page
        pages.push(1);
        // Add first ellipsis if needed
        if (currentPage > 3) pages.push('...');

        // Add pages around current page
        for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
            pages.push(i);
        }

        // Add last ellipsis if needed
        if (currentPage < totalPages - 2) pages.push('...');
        // Add last page if not already included
        if (currentPage !== totalPages) pages.push(totalPages);

        return pages;
    };

    return (
        <div className="flex justify-center items-center gap-2 mt-6">
            {/* Previous page button */}
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="p-1.5 rounded-md bg-white border border-[#DFE3E8] text-[#C4CDD5] disabled:bg-[#919EAB] disabled:cursor-not-allowed hover:bg-[#f5f1f1] transition-colors"
            >
                <ChevronLeft size={20} />
            </button>

            {/* Page numbers and ellipsis */}
            {getVisiblePages().map((page, index) => (
                page === '...' ? (
                    // Render ellipsis
                    <span key={`ellipsis-${index}`} className="px-3 py-1">...</span>
                ) : (
                    // Render page number button
                    <button
                        key={page}
                        onClick={() => onPageChange(page)}
                        className={`px-3 py-1 rounded-md min-w-[40px] ${currentPage === page
                                ? 'bg-white border border-[#BA1A1A] text-[#BA1A1A] font-bold'
                                : 'bg-white text-black font-bold border border-[#DFE3E8] hover:bg-gray-300'
                            }`}
                    >
                        {page}
                    </button>
                )
            ))}

            {/* Next page button */}
            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="p-1.5 rounded-md bg-white border border-[#DFE3E8] text-[#C4CDD5] disabled:bg-[#919EAB] disabled:cursor-not-allowed hover:bg-[#f5f1f1] transition-colors"
            >
                <ChevronRight size={20} />
            </button>
        </div>
    );
};

export default Pagination;