import React from 'react'
//image
import img from '../assets/images/pc-mt4-image.png'
import checkMark from '../assets/icons/checkmark-ico.svg'

export const SwapRatesInMT5 = () => {
    return (
        <div className='' style={{background : "linear-gradient(135deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 60%,rgb(147 180 249) 100%)"}}>
            <div className='container-max mx-auto sm:py-24 '>
                <div className='grid md:grid-cols-2 -md:grid-rows-2'>
                    <div>
                        <h3 className='text-4xl font-bold text-mons-blue-1 mb-3 sm:text-left text-center' >How to Find Monstrade Swap Rates in Own Platform and MT5?
                        </h3>
                        <p className='text-mons-blue-1 text-xl mb-10 sm:text-left text-center' >To check the latest rates on Own Platform and MT5 accounts:</p>
                        <ul>
                            <li className='mb-4'> <img className='w-6 inline-block' src={checkMark} alt="Checkmark" />Go to “Market Watch” section</li>
                            <li className='mb-4'> <img className='w-6 inline-block' src={checkMark} alt="Checkmark" />Right-click to select “Symbols”</li>
                            <li className='mb-4'> <img className='w-6 inline-block' src={checkMark} alt="Checkmark" />Choose the forex pair you want to trade</li>
                            <li className='mb-4'> <img className='w-6 inline-block' src={checkMark} alt="Checkmark" />Click on the “Properties” for the pair, (on MT5, please select 'Specification')</li>
                            <li className='mb-4'>
                                <img className='w-6 inline-block' src={checkMark} alt="Checkmark" />
                                All the information on the pair, such as forex swap rate,
                                stop level, initial margin and more, will be displayed
                            </li>
                        </ul>
                    </div>
                    <div className='flex' >
                        <div className='m-auto' >
                            <img src={img} alt='mt5'></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
