import React from 'react'
import { SpreadTable } from '../components/Tables/SpreadTable'
import { spreadTableContent } from '../features/content/spreadTables.content'

export const ForexSpreadSearch = () => {
    const tables = spreadTableContent();

    return (
        <div className='' id="anchor">
            <div className='container-max mx-auto'>
                <div className='p-24 md:w-3/4 mx-auto sm:block hidden' >
                    <h3 className='text-4xl font-bold ' >Spreads</h3>
                    <p className='pt-4'>Spreads on our MetaTrader 5 platform are variable with quotes from major international financial institutions. In times of liquid our industry-leading spreads can go as low as 0.0 pips. Ours more typical spreads are listed below.</p>
                </div>
                <div className='sm:mt-0 mt-[-60px] sm:mb-0 mb-16'>
                    {tables.map( (table, index) => {
                        return <SpreadTable key={index} table={table} />
                    })}
                </div>
            </div>
        </div>
    )
}
