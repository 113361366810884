import React from 'react'
//containers
import { ForexMarketHours } from '../containers/ForexMarketHours'
//components
import { BannerContainer } from '../components/Containers/BannerContainer'
import { StartTrading } from '../containers/StartTrading'
import { TradingTimeTables } from '../containers/TradingTimeTables'
//image
import bgImage from '../assets/images/banners/trading-hours-banner.png'
import TradingHoursMT5 from '../components/TradingHoursMT5'
import PricingMenu from '../components/PricingMenu';
import { BannerContainerV2 } from '../components/Containers/BannerContainerV2'

export const TradingHours = () => {

  const landingOptions = {
    image : bgImage,
    container : {
      width : 'w-3/4',
      padding : 'py-20'
    },
    header: {
      text : 'Forex Trading Hours',
      size: 'text-3xl'
    },
    subHeader: {
      text : "With Monstrade you can trade Forex around the clock. Our award-winning support team are available 24 hours a day, 5 days a week to help ensure a seamless trading day experience."},
    button: {
        bgcolor: "bg-white",
        textcolor: "text-monstrade-blue",
        text: "Start Trading",
        padding: "pt-4"
    }
  }

  return (
    <>
      <BannerContainerV2 options={landingOptions}/>
      <PricingMenu isActive="tradinghours"/>
      <ForexMarketHours />
      <TradingHoursMT5/>
      <TradingTimeTables />
      <StartTrading />
    </>
  )
}
