import React,{useEffect, useState} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { SliderCircles } from './SliderCircles';

import Slider1 from '../../assets/images/v2-slider-1.png'
import Slider2 from '../../assets/images/v2-slider-2.png'
import Slider3 from '../../assets/images/v2-slider-3.png'
import Slider4 from '../../assets/images/v2-slider-4.png'
import Slider5 from '../../assets/images/v2-slider-5.png'

import Monstore from '../../assets/icons/monstore-logo.svg'
import Monstore2 from '../../assets/images/slider-monstore.svg'

export const SliderWithImage = () => {

    const [carouselIndex, setCarouselIndex] = useState(0)
    const [content, setContent] = useState({})

    const changeHandler = (item) => {
        console.log("ch:",item)
        setCarouselIndex(item)
    }

    const sliders = [
        {
            id: 0,
            title:"You will enjoy trading even more with ",
            desc:"There is no limit to promotions and rewards on MonsTrade! Earn tickets as you invest and convert your tickets into rewards.",
            image: Slider1
        },
        {
            id: 1,
            title:"You will enjoy trading even more with",
            desc:"There is no limit to promotions and rewards on MonsTrade! Earn tickets as you invest and convert your tickets into rewards.",
            image: Slider2
        },
        {
            id: 2,
            title:"You will enjoy trading even more with",
            desc:"There is no limit to promotions and rewards on MonsTrade! Earn tickets as you invest and convert your tickets into rewards.",
            image: Slider3
        },
        {
            id: 3,
            title:"You will enjoy trading even more with",
            desc:"There is no limit to promotions and rewards on MonsTrade! Earn tickets as you invest and convert your tickets into rewards.",
            image: Slider4
        },
        {
            id: 4,
            title:"You will enjoy trading even more with ",
            desc:"There is no limit to promotions and rewards on MonsTrade! Earn tickets as you invest and convert your tickets into rewards.",
            image: Slider5
        },
    ]

    useEffect(() => {
        setContent(sliders[carouselIndex])
      },[carouselIndex]);

  return (
    <>
        <div className='lg:py-20 relative'>
            <div className=''>
                <div className='flex lg:flex-row flex-col-reverse gap-8 bg-[#EAF3F9] rounded-xl items-center lg:text-left text-center px-4'>
                    <div className='px-2'>
                        <p className='text-4xl inline-block'>{content.title}</p>
                        <img className='lg:mx-0 mx-auto inline-block' src={Monstore2} alt="" />
                        <p className='py-5'>{content.desc}</p>
                        <SliderCircles/>
                        <div className='py-10 flex items-center gap-[32px]'>
                            <button className='bg-monstrade-blue lg:mx-0 mx-auto text-white font-semibold rounded-3xl p-2 px-4'><a href="/privileges/store">Explore Store ➔</a></button>
                            <div className='lg:mx-0 mx-auto w-[100px] pl-4'>
                                <img src={Monstore} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='lg:mt-[-80px] -mt-[50px]'>
                        <Carousel className='overflow-visible md:w-auto w-[280px] mx-auto' autoPlay={true} infiniteLoop={true} showIndicators={false} emulateTouch={true} onChange={changeHandler}>
                        {sliders.map((slider)=>{
                            return(
                                <div className=''>
                                    <img src={slider.image} />
                                </div>
                                
                            )
                        })}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
