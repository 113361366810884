import React from "react";
import TopBanner from "../components/TopBanner";
import CashbackImg from "../assets/images/mons-blue-bg.png"
import ZeroCommisionIco from "../assets/icons/zero_commision.svg"
import HandMoneyIco from "../assets/icons/hand_money.svg"
import CardIco from "../assets/icons/card_ico.svg"
import PercentIco from "../assets/icons/circle_percent.svg"
import CashbackContent from "../components/PromotionComps/CashbackContent";
import CashbackTable from "../components/PromotionComps/CashbackTable";
import CashbackTerms from "../components/PromotionComps/CashbackTerms";
import CashbackAccordion from "../components/PromotionComps/CashbackAccordion";
import CashbackEnd from "../components/PromotionComps/CashbackEnd";
import PromotionButtons from "../components/PromotionComps/PromotionButtons";
import PromotionFooter from "../components/PromotionComps/PromotionFooter";
import { BannerContainer } from "../components/Containers/BannerContainer";
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";
import { BannerContainerV2 } from "../components/Containers/BannerContainerV2";

const mainTopic = "Get Cashback From Monstrade"

const text1 = "Zero fees on credit card/electronic wallet deposits and withdrawals"
const text2 = "Zero fees on deposits / withdrawals made by wire transfer over 200 USD, or currency equivalent"
const text3 = "Instant account funding via all credit cards/electronic wallets"
const text4 = "No hidden commissions"


const landingOptions = {
    image: CashbackImg,
    mainZindex: 'z-[-1]',
    header: {
        text: 'Get Cashback From Monstrade',
        size: 'text-[54px]',
    },
    subHeader: {
        text: "Get cashback as you trade on MonsTrade",
        align: "text-center",
        size: 'text-[24px]',
    }
}
const cards = [
    { header: 'Zero Fees', description: 'Zero fees on credit card/electronic wallet deposits and withdrawals', icon: ZeroCommisionIco, link: '' },
    { header: 'Zero Commission', description: 'Zero fees on deposits / withdrawals made by wire transfer over 200 USD, or currency equivalent', icon: HandMoneyIco, link: '' },
    { header: 'Instant Funding', description: 'Instant account funding via all credit cards/electronic wallets', icon: CardIco, link: '' },
    { header: 'No Comission', description: 'No hidden commissions', icon: PercentIco, link: '' },
]


const CashbackPage = () => {
    return (
        <>
            {/* <TopBanner firstIcon={ZeroCommisionIco} secondIcon={HandMoneyIco} thirdIcon={CardIco} forthIcon={PercentIco} mainTopic={mainTopic} text1={text1} text2={text2} text3={text3} text4={text4} bgimage={CashbackImg} is4Columns={true} imageGradient="linear-gradient(90deg, rgba(255, 255, 255, 0.9), rgba(0, 0, 0, 0.5))" />  */}
            <BannerContainerV2 options={landingOptions}>
                <div className="" >
                    <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'grid-cols-2'} />
                </div>
            </BannerContainerV2>
            <div className=" bg-slate-50 py-8">
                <div className="container-max mx-auto bg-white py-10">
                    <CashbackContent />
                    <CashbackTable />
                    <CashbackTerms />
                    <CashbackAccordion />
                    <CashbackEnd />
                    <PromotionButtons depositAccount={true} />
                </div>
            </div>
            {/* <PromotionFooter /> */}
        </>
    )
}

export default CashbackPage;