import React from 'react';

const IndicesTextArea = (props) => {
    return(
        <>
            <div className=''>
                <div className='container-max mx-auto'>
                    <div className='grid-rows-2 text-center py-10'>
                        <div className='lg:py-8 py-4'>
                            <p className='text-4xl'>{props.textAreaTitle}</p>
                        </div>
                        <div className='max-w-[1066px] text-gray-600'>
                            <p>{props.textAreaContent}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IndicesTextArea;