import React from 'react';
import MetatraderIco from '../assets/icons/metatrader5.svg'

const PhoneMockup = ({ pageName, title, subtitle, desc, linkHref, linkText }) => {
    return (
        <div className='phoneMockup bg-black'

        >
            <div className="w-screen -mb-2 mx-auto lg:h-[64vw] xl:max-h-[60vw] h-auto xl:bg-transparent  break-words grid lg:grid-cols-2 px-2 py-5">
                <div></div>
                <div className="flex flex-col justify-center items-center">
                    <h3 className='font-medium text-white lg:text-[40px] text-lg leading-[46px]'>{title}</h3>
                    <p className='text-[#246FFF] text-3xl font-light text-center'>{subtitle}</p>
                    <p className=' w-full mx-auto px-4 text-white text-[22px] leading-[25px] font-normal text-center'>
                        {desc} {pageName}.
                    </p>
                    <div className='py-4'>
                        <a className="text-[#37CFFF] text-lg underline-offset-2" href={linkHref}>{linkText}</a>
                    </div>
                    <div className='xl:pt-[13vw] pt-10'>
                        <img className='mx-auto' src={MetatraderIco} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhoneMockup;