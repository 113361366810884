import React from 'react'
import { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Phone1 from '../assets/images/xmas-phone1.png'
import Phone2 from '../assets/images/xmas-phone2.png'
import Phone3 from '../assets/images/xmas-phone3.png'

import Image from '../assets/images/x-mas-image4.png'
import ImageMobile from '../assets/images/x-mas-image4-mobile.png'

// import PDF from '../assets/documents/monspdf.pdf'

export const XMasStepToJoin = () => {

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const terms = [
    {
      id: 1,
      text: "The raffle will start on 01.12.2024 at 00:00 GMT and end on 31.12.2024 at 23:59 GMT."
    },
    {
      id: 2,
      text: "The raffle results will be announced on 03.01.2025 on all social media accounts and the official website."
    },
    {
      id: 3,
      text: "The winners will be contacted through their advisors to receive their prizes."
    },
    {
      id: 4,
      text: "Investors with an active balance of 250 USD or more will automatically be assigned tickets. (For example, an investor with a balance of 8750 USD will be assigned 3 Diamond tickets, 2 Gold tickets, and 1 Silver ticket.)"
    },
    {
      id: 5,
      text: "Investors will earn tickets when they deposit funds, thereby gaining a chance to win the raffle. For each 250 USD investment, they will earn 1 Silver ticket, for each 500 USD investment, 1 Gold ticket, and for each 2,500 USD investment, 1 Diamond ticket."
    },
    {
      id: 6,
      text: "A single investor may be assigned multiple tickets, either in the same or different categories."
    },
    {
      id: 7,
      text: "Tickets are assigned based on the Meta ID number. If an investor has multiple Meta accounts, tickets will be assigned separately for each Meta account."
    },
    {
      id: 8,
      text: <>Investors can track the type and number of tickets they have earned by visiting the 'PROFILE-MY TICKETS' section in the Monstrade app or by visiting <a href="https://monstrade.co/christmas-draw" className="text-blue-600 underline">https://monstrade.co/christmas-draw</a></>
    },
    {
      id: 9,
      text: "The assigned tickets will expire on 31.12.2024 and cannot be carried over to the following year. For every investor who earns a ticket and meets the raffle conditions, any loss incurred on their first trade in 2025 will be covered by our company."
    },
    {
      id: 10,
      text: "The loss reimbursement is made in Monscredit, and if there is a SWAP in the investor's trades (i.e., if the trades are carried over to the next day), the loss will not be covered by the company."
    },
    {
      id: 11,
      text: "If an investor continuously deposits and withdraws the full amount, they will not earn any tickets, and this will be considered misuse of the application, resulting in exclusion from the raffle."
    },
    {
      id: 12,
      text: "To activate the assigned tickets, the investor must make a trade in their investment account after the ticket has been assigned."
    },
    {
      id: 13,
      text: "For all categories, the investor must reach the transaction volume equal to the investment amount. Tickets will be deleted if the investor withdraws funds. (For example, an investor who earns 2 Gold tickets after making a 1000 USD investment will lose those 2 tickets if they withdraw 750 USD. With the remaining 250 USD, they will earn 1 Silver ticket.)"
    },
    {
      id: 14,
      text: "All raffle rights are reserved by Monstrade, and Monstrade reserves the right to change the raffle rules in cases of misuse."
    }
  ];

  return (
    <>
      <div className='bg-[#850C0C] relative'>
        <div className='text-center text-white py-6'>
          <p className='text-2xl'>STEPS TO JOIN</p>
          <p className='max-w-[450px] mx-auto'>In order to enter our draw, make sure you copmlete all 3 steps within the New Year’s Draw.</p>
        </div>
        {windowSize[0] > 1024
          ?
          <>
            <div className='grid grid-cols-3 mx-36'>
              <div>
                <img className='mx-auto' src={Phone1} alt="" />
              </div>
              <div>
                <img className='mx-auto' src={Phone2} alt="" />
              </div>
              <div>
                <img className='mx-auto' src={Phone3} alt="" />
              </div>
            </div>
          </>
          :
          <Carousel className='max-w-[260px] mx-auto sm:hidden block pb-4' autoPlay={true} infiniteLoop={true} interval={3000} showThumbs={false}
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "hidden" : "hidden"
                    }`}
                  onClick={clickHandler}
                >
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasNext) => {
              return (
                <div
                  className={`${hasNext ? "hidden" : "hidden"
                    }`}
                  onClick={clickHandler}
                >
                </div>
              );
            }}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const defStyle = { color: "white", cursor: "pointer" };
              const style = isSelected
                ? { ...defStyle, color: "yellow" }
                : { ...defStyle };
              return (
                <span
                  style={style}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                >
                  {"•"}
                </span>
              );
            }}
          >
            <img src={Phone1} alt="" />
            <img src={Phone2} alt="" />
            <img src={Phone3} alt="" />
          </Carousel>
        }
      </div>
      <div className='bg-[#850C0C]'>
        <div className='py-8'>
          <p className='text-center text-white'>Mark your calendar! Our New Year’s Eve draw* runs from 1-31 December 2024</p>
        </div>
        <div className='w-full text-center pb-8'>
          <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard"><button className='xmas-green-button'>Register Now</button></a>
        </div>
        <div className='text-center text-white pb-16'>
          <p className='pb-4'>Already a client? Log in <a href="https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard"><span className='underline'>here</span></a> to participitate.</p>
          <p>All trading involves risk. It is possible to lose all your capital. <br /><span className='underline'>*Terms & Conditions</span> apply.</p>
        </div>

        {/* terms */}
        <div className='bg-[#ECECEC] flex pb-[120px] px-5' id='terms'>
          <div className="max-w-7xl mx-auto bg-white rounded-[47px] shadow-lg overflow-hidden -mt-11">
            <div className=" lg:p-12 lg:px-20 p-4">
              <h1 className="font-bold text-black mb-2 lg:text-4xl text-3xl">CAMPAIGN TERMS</h1>
              <div className="space-y-6">
                <p className="text-[#838383] mb-6 lg:text-lg text-base">
                  Monstrade is organizing an exciting raffle to share the joy of the New Year with our investors. By participating in the raffle, you can win tickets in different categories and increase your chances of winning big prizes. Detailed terms and conditions of the campaign are provided below:
                </p>

                <div className="space-y-4">
                  {terms.map((term) => (
                    <div key={term.id} className="flex lg:gap-2">
                      <span className="text-[#838383] font-normal min-w-[24px] lg:text-lg text-base ">{term.id}.</span>
                      <p className="text-[#838383] font-normal lg:text-lg text-base">{term.text}</p>
                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* /terms */}
      </div>
      <div>
        {
          windowSize[0] > 420 ?

            <div className="relative lg:h-[646px] h-[300px]">
              <div
                className="absolute inset-0 bg-[#24000b] bg-center bg-no-repeat"
                style={{ backgroundImage: `url("${Image}")` }}
              />
              <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-transparent via-35% to-transparent">
                <p className="text-white text-center text-xl max-w-[600px] mx-auto font-semibold pt-6">
                  Start investing right away! Join the draw and seize the chance to win one of dozens of prizes or even more!
                </p>
              </div>
            </div>

            :

            <div className={"h-[194px] bg-center bg-no-repeat"} style={{ backgroundImage: `url("${ImageMobile}")` }}>
              <p className='text-white text-center text-sm mx-auto font-medium pt-36'>Start investing right away! Join the draw and seize the chance to win one of dozens of prizes or even more!</p>
            </div>
        }
      </div>
    </>
  )
}
