import React from 'react'
import {useState, useEffect} from 'react'

import Image from '../assets/images/x-mas-ticket-bg.png'
import DoubleDot from '../assets/images/double-dot.png'

import SilverTicket from '../assets/images/silver-ticket.png'
import GoldTicket from '../assets/images/golden-ticket.png'
import DiamondTicket from '../assets/images/diamond-ticket.png'

export const XMasTicketPage = () => {

    const targetDate = new Date('January 1, 2024 00:00:00 GMT+00:00');
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000);

        return () => clearInterval(timerInterval);
    }, [targetDate]);

    function calculateTimeLeft(targetDate) {
        const now = new Date().getTime();
        const difference = targetDate - now;

        if (difference <= 0) {
            // If the target date has passed, set timeLeft to 0
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    }


  return (
    <>
    <div className={"container-full bg-[#24000b] relative bg-no-repeat"} style={{ backgroundImage: `url("${Image}")` }}>
        <p className='countdown-blue-2 pt-12 outline-blue-600'>2024</p>
        <p className='text-center text-white text-2xl max-w-[300px] pt-4 mx-auto font-bold'>The Remaining Time For The Big New Year Draw</p>

        <div className='grid grid-cols-11 items-center py-6 mx-4'>
                <div className='col-span-2'>
                    <div className='countdown-div h-[72px] max-w-[125px] mx-auto p-2'>
                        <div className='countdown-inner-div h-[52px]'>
                            <p className='countdown-red-2 outline-red-600 pt-2'>{timeLeft.days}</p>
                        </div>
                    </div>
                    <div><p className='text-center text-xs text-white'>DAYS</p></div>
                </div>
                <div className='block w-5 mx-auto'>
                    <img className='mx-auto pb-4' src={DoubleDot} alt="" />
                </div>
                <div className='col-span-2'>
                <div className='countdown-div h-[72px] max-w-[125px] mx-auto p-2'>
                        <div className='countdown-inner-div h-[52px]'>
                            <p className='countdown-red-2 outline-red-600 pt-2'>{timeLeft.hours}</p>
                        </div>
                    </div>
                    <div><p className='text-center text-xs text-white'>HOURS</p></div>
                </div>
                <div className='block w-5 mx-auto'>
                    <img className='mx-auto pb-4'  src={DoubleDot} alt="" />
                </div>  
                <div className='col-span-2'>
                <div className='countdown-div h-[72px] max-w-[125px] mx-auto p-2'>
                        <div className='countdown-inner-div h-[52px]'>
                            <p className='countdown-red-2 outline-red-600 pt-2'>{timeLeft.minutes}</p>
                        </div>
                    </div>
                    <div><p className='text-center text-xs text-white'>MINUTES</p></div>
                </div>
                <div className='block w-5 mx-auto'>
                    <img className='mx-auto pb-4'  src={DoubleDot} alt="" />
                </div>
                <div className='col-span-2'>
                <div className='countdown-div h-[72px] max-w-[125px] mx-auto p-2'>
                        <div className='countdown-inner-div h-[52px]'>
                            <p className='countdown-red-2 outline-red-600 pt-2'>{timeLeft.seconds}</p>
                        </div>
                    </div>
                    <div><p className='text-center text-xs text-white'>SECONDS</p></div>
                </div>
        </div>
    </div>
    <div className='bg-[#B1030D] py-8'>
        <div className='grid grid-cols-2 gap-2'>
            <div>
                <p className='text-white text-sm'>Name</p>
                <input className='rounded-lg max-w-[175px] py-1 border-2 border-red-500' type="text" placeholder='John' />
            </div>
            <div>
                <p className='text-white text-sm'>Surname</p>
                <input className='rounded-lg max-w-[175px] py-1 border-2 border-red-500' type="text" placeholder='Doe' />
            </div>
            <div className='col-span-2'>
                <p className='text-white text-sm'>Email</p>
                <input className='rounded-lg w-full max-w-[368px] py-1 border-2 border-red-500' type="text" placeholder='info@rot3.com' />
            </div>
            <div>
                <p className='text-white text-sm'>Phone Number</p>
                <input className='rounded-lg max-w-[175px] py-1 border-2 border-red-500' type="text" placeholder='+44' />
            </div>
            <div>
                <p className='text-white text-sm'>Meta ID</p>
                <input className='rounded-lg max-w-[175px] py-1 border-2 border-red-500' type="text" placeholder='651289' />
            </div>
        </div>
    </div>
    <div className='py-4'>
        <p className='text-[#A62424] text-center text-2xl font-bold'>Your TICKETS</p>
        <div className='grid grid-cols-2 gap-4 py-4'>
            <div>
                <img src={SilverTicket} alt="" />
            </div>
            <div>
                <img src={GoldTicket} alt="" />
            </div>
            <div>
                <img src={DiamondTicket} alt="" />
            </div>
        </div>
    </div>
    </>
  )
}
