import React from "react";
import ContentForexNews from "../containers/Content.ForexNews";
import LandingBannerForexNews from "../containers/LandingBanner.ForexNews";
import { BannerContainerV2 } from "../components/Containers/BannerContainerV2";
import image from "../assets/images/banners/forex-news-banner.png";

const landingOptions = {
    image : image,
    imageGradient: "linear-gradient(90deg, #0052FF94, #0051ff42",
    container : {
      width : 'w-3/4',
      padding : 'py-40'
    },
    header: {
      text : 'Forex News',
      size: 'text-3xl'
    },
    subHeader: {
        text : "Economic events are a vital part of your trading plan and strategy. \
        With Monstrade’s economic calendar, you can look ahead to see upcoming \
        economic releases, reports, decisions and events that can potentially \
        affect your trading or increase your opportunities."
    },
    button: {
        bgcolor: "bg-white",
        textcolor: "text-[#0052FF]",
        bordercolor:"border-[#0052FF]",
        text: "Join Now",
        padding: "pt-4"
    }
  }


const ForexNews = () => {
    return (
        <>
            <BannerContainerV2 options={landingOptions} />
            {/* <LandingBannerForexNews /> */}
            <ContentForexNews />
        </>
    )
}

export default ForexNews;