import React from "react";
import accountTypeForexContent from "../features/content/accountTypeForex.content";
import AccountTypeCardSlider from "../components/Slider/AccountTypeCarousel";
import Button from "../components/Button"

const accountTypes = accountTypeForexContent()

const AvailableAccountTypes = () => {
    return (
        <>
            <div className=" banner-gradient overflow-hidden">
                <div className="container-max mx-auto">
                    <div className="md:py-20 py-10 text-center text-white">
                        <p className="text-3xl">Available on the Following Account Types</p>
                        <p className="text-base pt-6">
                            Monstrade offers to its clients several types of accounts for Forex investment
                            and the clients can choose the one which is the most suitable for them.
                        </p>
                    </div>
                    <div className="text-center text-sm">
                        <p className="text-cyan-400">
                            <a href="/accounts/trading/demo-account">All types of accounts</a>
                        </p>
                    </div>
                    <div className="md:py-24 py-12">
                        <AccountTypeCardSlider accountTypes={accountTypes} />
                    </div>
                </div>
                <div className="absolute w-full -mt-3">
                    <Button
                     content="TRADE FOREX" 
                     color="bg-monstrade-button-green" 
                     py="py-3"
                     px="lg:px-6 px-2"
                     isArrow={true}
                     />
                </div>
            </div>
        </>
    )
}

export default AvailableAccountTypes;