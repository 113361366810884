import React from "react";
import TopBanner from "../components/TopBanner";
import IndicesImg from "../assets/images/indices_img.png"
import Button from "../components/Button"
import Table from "../components/Table"
import IndicesBanner from "../components/IndicesBanner";
import TextArea from "../components/TextArea";
import PhoneMockup from '../components/PhoneMockup'
import StockContentTable from "../components/StockContentTable"
import Security from "../components/Security";
import BalanceIco from "../assets/icons/balance.svg"
import ForexIco from "../assets/icons/forex.svg"
import HourglassIco from "../assets/icons/hourglass.svg"
import { SimpleCardListContainer } from "../components/ContainerLayout/SimpleCardListContainer";
import { BannerContainerV2 } from "../components/Containers/BannerContainerV2";
import AccountSlider from "../containers/AccountSlider";

const pageName = "Indices"

const textAreaTitle = "What is indices trading?"
const textAreaContent = "Indices are trading tools, which consist of a group of bonds and show changes in their prices. For example, an index includes several stocks and its price is calculated based on the total cost of bonds or capitalization of issuers. A set of bonds, which the index consists of, shows what information relating to the current economic situation may be received when analyzing it. Thanks to using indices in trading, traders have an opportunity to diversify their investment portfolio, collect and analyze information relating to the global economy or one of its sectors. <br/> <br/> Monstrade offers its clients access to some of the best conditions for investing in indices in the industry."
const landingOptions = {
  image: IndicesImg,
  imageGradient: 'linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(61, 59, 59, 0.5))',
  mainZindex: 'z-[-1]',
  header: {
    text: 'Are You Ready Index Trading',
    size: 'text-3xl',
  },
  subHeader: {
    text: "Invest in indices with tight spreads and no commission for the trading volume of 1 million USD.",
    align: "text-center"
  }
}
const cards = [
  { header: 'Leverage up to 1:100', description: 'Trade with the leverage value up to 1:100 in any event of the account type.', icon: BalanceIco, link: '' },
  { header: 'Low spreads', description: 'Average spread for DE40 on ECN and Prime accounts 0.5 EUR.', icon: ForexIco, link: '' },
  { header: 'High trade speed', description: 'Minimum lot of trading volume for all indices  0.01.', icon: HourglassIco, link: '' },
]

const accountTypes = [
  {
    title: "Prime Account",
    description: "An exceptional account type that allows to trade through a multi-asset platform directly to the Stocks/Trade with access to the financial market.",
    initialDeposit: "1000 USD",
    executionType: "Market Execution",
    minOrderVolume: "0.01",
    minStopVolume: "0.01",
    spreadFrom: "1.25",
    commission: "Variable",
    bonus: "Variable",
    numberOfAssets: "150"
  },
  {
    title: "ECN Account",
    description: "An exceptional account type that allows to trade through a multi-asset platform directly to the Stocks/Trade with access to the financial market.",
    initialDeposit: "2500 USD",
    executionType: "Market Execution",
    minOrderVolume: "0.01",
    minStopVolume: "0.01",
    spreadFrom: "1.00",
    commission: "Variable",
    bonus: "Variable",
    numberOfAssets: "150"
  },
  {
    title: "Cent Account",
    description: "An exceptional account type that allows to trade through a multi-asset platform directly to the Stocks/Trade with access to the financial market.",
    initialDeposit: "100 USD",
    executionType: "Market Execution",
    minOrderVolume: "0.01",
    minStopVolume: "0.01",
    spreadFrom: "1.50",
    commission: "Variable",
    bonus: "Not Variable",
    numberOfAssets: "150"
  },
  {
    title: "Classic Account",
    description: "An exceptional account type that allows to trade through a multi-asset platform directly to the Stocks/Trade with access to the financial market.",
    initialDeposit: "100 USD",
    executionType: "Market Execution",
    minOrderVolume: "0.01",
    minStopVolume: "0.01",
    spreadFrom: "1.50",
    commission: "Variable",
    bonus: "Variable",
    numberOfAssets: "150"
  },

];

const tableHeaders = [
  "Initial deposit",
  "Execution Type",
  "Min. Order Volume",
  "Min. Stop Volume",
  "Average Spread",
  "Comision for trading volume of 1 million USD",
  "The Number of Assets",
  "Bonus",
];


const IndicesPage = () => {
  return (
    <>

      <BannerContainerV2 options={landingOptions}>
        <div className="" >
          <SimpleCardListContainer cards={cards} textClass={'text-white'} containerClass={'grid-cols-3'} />
        </div>
      </BannerContainerV2>
      <div className="absolute left-1/2 -translate-x-1/2 -mt-3">
        <Button content="TRADE INDICES" color="bg-monstrade-button-green" py="py-3" px="lg:px-6 px-2" isArrow={true} />
      </div>
      {/* <Table type={'indice'} /> */}
      <IndicesBanner />
      <TextArea textAreaTitle={textAreaTitle} textAreaContent={<div dangerouslySetInnerHTML={{ __html: textAreaContent }} />} />
      <PhoneMockup
        pageName={pageName}
        title="Monstrade trading platforms"
        subtitle={"Best platforms in the industry for indices trading"}
        desc={"You can reach out to the Monstrade from any devices to trade with competitive conditions. All you need to do that just choose a platform and start your investment in Indices."}
        linkHref={"/platforms/computer/mt5-for-pc"}
        linkText={"Download a platform and invest in forex"}
      />
      <AccountSlider
        accounts={accountTypes}
        tableHeaders={tableHeaders}
        title={"Trade indices with below account types"}
        desc={"Monstrade offers to its clients several types of accounts for indices investment and the clients can choose the one which is the most suitable for them."}
      />
      <StockContentTable />
      <Security />
    </>
  )
}

export default IndicesPage;