import React from 'react'
import bgImage from '../assets/images/banners/calculator-banner.png'
import { Calculators } from '../containers/Calculators'
import { BannerContainer } from '../components/Containers/BannerContainer'
import { BannerContainerV2 } from '../components/Containers/BannerContainerV2'

export const Calculator = () => {
  const options = {
    image : bgImage,
    container : {
      width : 'w-3/4',
      padding : 'py-20'
    },
    header: {
      text : 'Forex Calculators',
      size: 'text-3xl'
    },
    subHeader: {
      text : 'Use Monstrade’s pratical annd effcient calculators to '+
                  'check your trading costs and follow your trading strategy. ' +
                  'Scroll down and choose your calculator.'},
    button:{}
  }
    const landing = {
                      h1: 'FOREX CALCULATORS', 
                      p: 'Use Monstrade’s pratical annd effcient calculators to '+
                          'check your trading costs and follow your trading strategy. ' +
                          'Scroll down and choose your calculator.',
                      padding: 'xl:pl-14'  }
                      
  return (
    <div>
        <BannerContainerV2 options={options}/>
        <Calculators />
    </div>
  )
}
