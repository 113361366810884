import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
//images
import deviceImgPc from '../assets/images/device-pc.png'
import deviceImgMac from '../assets/images/device-mac.png'
import deviceImgWeb from '../assets/images/device-web.png'
import deviceImgIpad from '../assets/images/device-ipad.png'
import deviceImgTablet from '../assets/images/device-tablet.png'
import deviceImgIphone from '../assets/images/device-iphone.png'
import deviceImgAndroid from '../assets/images/device-android.png'

export const PlatformDeviceContainer = () => {
    return (
        <div className='' >
            <div className='container-max mx-auto'>
                <div className='flex justify-center my-10' >
                    <div className='mr-4' >
                        <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-monstrade-green px-4 py-2 inline-block rounded text-center text-white hover:text-white hover:bg-mons-green-1 font-semibold'>Open Real Account</a>
                    </div>
                    <div>
                        <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-[#318BBD] px-4 py-2 inline-block rounded text-center text-white hover:text-white hover:bg-[#143b50] font-semibold'  >Open Demo Account</a>
                    </div>
                </div>
                <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-rows-1 my-20 gap-14'>
                    <div className='border-r-[1px] px-4'>
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgPc} alt="" className='max-h-[100%]' />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3 text-center'>MT5 For Pc
                            <div className='mb-2 ml-2 inline-block' >
                                <FontAwesomeIcon icon="fa-brands fa-windows" className='text-mons-gray-4' size='lg' />
                            </div>
                            </h3>
                            <p className='text-sm mb-2' >Turn your windows device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 pt-4 justify-center gap-2'>
                                <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 text-white font-semibold hover:text-white hover:bg-monstrade-green py-2 rounded text-center '>Start Now</a>
                                <a href='https://download.mql5.com/cdn/web/monstrade.dooel.skopje/mt5/monstrade5setup.exe' target="_blank" className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >Download</a>
                            </div>
                        </div>
                    </div>
                    <div className='border-r-[1px] px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgMac} alt="" className='max-h-[100%]' />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3 text-center'>MT5 For Mac
                            <div className='mb-2 inline-block ml-2' >
                                <FontAwesomeIcon icon="fa-brands fa-apple" className='text-mons-gray-4' size='lg' />
                            </div>
                            </h3>
                            <p className='text-sm mb-2' >Turn your apple device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2 pt-4'>
                                <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 text-white font-semibold py-2 rounded hover:text-white hover:bg-monstrade-green text-center '>Start Now</a>
                                <a href='https://download.mql5.com/cdn/mobile/mt5/ios?server=Monstrade-Server' target="_blank" className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >Download</a>
                            </div>
                        </div>
                    </div>
                    <div className='px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgWeb} alt="" className='max-h-[100%]' />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3 text-center'>MT5 For Web Trader
                            <div className='mb-2 inline-block ml-2' >
                                <FontAwesomeIcon icon="fa-solid fa-globe" className='text-mons-gray-4 mr-2' />
                                <FontAwesomeIcon icon="fa-brands fa-chrome" className='text-mons-gray-4 mr-2' />
                                <FontAwesomeIcon icon="fa-brands fa-internet-explorer" className='text-mons-gray-4' />
                            </div>
                            </h3>
                            <p className='text-sm mb-2' >Turn your all computer device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 pt-4 justify-center gap-2'>
                                <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 text-white font-semibold py-2 hover:text-white hover:bg-monstrade-green rounded text-center '>Start Now</a>
                                <a href='/web-trader' target="_blank" className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >Download</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid lg:grid-cols-2 grid-rows-1 sm:px-24 gap-14 py-10'>
                    <div className='px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgIpad} alt="" />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3 text-center'>MT5 For Ipad
                            <div className='mb-2 inline-block ml-2' >
                                <FontAwesomeIcon icon="fa-brands fa-apple" className='text-mons-gray-4' size='lg' />
                            </div>
                            </h3>
                            <p className='text-sm mb-2' >Turn your ipad device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2 pt-4'>
                                <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 text-white font-semibold py-2 hover:text-white hover:bg-monstrade-green rounded text-center '>Start Now</a>
                                <a href='/web-trader'  target="_blank" className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >Download</a>
                            </div>
                        </div>
                    </div>
                    <div className='px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgTablet} alt="" />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3 text-center'>MT5 For Tablet
                            <div className='mb-2 inline-block ml-2' >
                                <FontAwesomeIcon icon="fa-brands fa-android" className='text-mons-gray-4' size='lg' />
                            </div>
                            </h3>
                            <p className='text-sm mb-2' >Turn your Android device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2 pt-4'>
                                <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 text-white font-semibold py-2 hover:text-white hover:bg-monstrade-green rounded text-center '>Start Now</a>
                                <a href='https://download.mql5.com/cdn/mobile/mt5/android?server=Monstrade-Server' target="_blank" className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >Download</a>
                            </div>
                        </div>
                    </div>
                    <div className='px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgIphone} alt="" />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3 text-center'>MT5 For Iphone
                            <div className='mb-2 inline-block ml-2' >
                                <FontAwesomeIcon icon="fa-brands fa-apple" className='text-mons-gray-4' size='lg' />
                            </div>
                            </h3>
                            <p className='text-sm mb-2' >Turn your ıphone device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2 pt-4'>
                                <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 text-white font-semibold py-2 hover:text-white hover:bg-monstrade-green rounded text-center '>Start Now</a>
                                <a href='/web-trader' target="_blank" className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >Download</a>
                            </div>
                        </div>
                    </div>
                    <div className='px-4' >
                        <div className='mb-2 h-[153px] flex justify-center' >
                            <img src={deviceImgAndroid} alt="" />
                        </div>
                        <div>
                            <h3 className='text-lg font-semibold text-mons-blue-1 mb-3 text-center'>MT5 For Android
                            <div className='mb-2 inline-block ml-2' >
                                <FontAwesomeIcon icon="fa-brands fa-android" className='text-mons-gray-4' size='lg' />
                            </div>
                            </h3>
                            <p className='text-sm mb-2' >Turn your Android device into a virtual trading desk.</p>
                            <div className='grid grid-cols-2 justify-center gap-2 pt-4'>
                                <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 text-white font-semibold py-2 hover:text-white hover:bg-monstrade-green rounded text-center '>Start Now</a>
                                <a href='https://download.mql5.com/cdn/mobile/mt5/android?server=Monstrade-Server' target="_blank" className='border-[1px] border-mons-blue-1 py-2 rounded text-mons-blue-1 text-center' >Download</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
