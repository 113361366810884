import React from 'react'
import { SimpleCard } from '../components/Cards/SimpleCard'
//images
import icon1 from '../assets/icons/platform2-icon-7.svg'
import icon2 from '../assets/icons/platform2-icon-2.svg'
import icon3 from '../assets/icons/platform2-icon-3.svg'
import icon4 from '../assets/icons/platform2-icon-4.svg'
import icon5 from '../assets/icons/platform2-icon-5.svg'
import icon6 from '../assets/icons/platform2-icon-6.svg'

export const WhyChoosePlatform = () => {
    const cards = [
        {
            header : ' Regulation', 
            description : 'You can rest assured that you’re trading with a fully regulated',
            icon : icon1
        },
        {
            header : 'Award-Winning Support', 
            description : 'Always feel supported by a professional, friendly, and available team.',
            icon : icon2
        },
        {
            header : 'Excellent Trading Conditions', 
            description : 'Trade with spreads starting from 0 pips, leverage up to 1:500, minimal slippage, and more.',
            icon : icon3
        },
        {
            header : 'Reliable Funding Methods', 
            description : 'Enjoy fast deposits and withdrawals with credible and reliable funding methods.',
            icon : icon4
        },
        {
            header : 'Free Education and Webinars', 
            description : 'Treat yourself to frequent Forex webinars and a library of educational articles.',
            icon : icon5
        },
        {
            header : 'Industry-Leading Platforms', 
            description : "Trade on the industry's best platforms for the ultimate, most helpful experience.",
            icon : icon6
        }
    ]
  return (
    <div className=''>
        <div className='container-max mx-auto'>
            <div className='text-center my-10' >
                <h3 className='text-3xl font-bold mb-4' >Why Would You Choose Monstrade?</h3>
                <p >Thousands of traders have trusted us, and here’s why you could also enjoy a trading journey with our platforms, trading conditions, and the entire team at your side.</p>
            </div>
            <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-rows-1 gap-10'>
                {cards.map( (item) => {
                    return <SimpleCard 
                                header={item.header} 
                                description={item.description}
                                icon={item.icon} />
                })}
            </div>
            <div className='text-center' >
                <div className='my-16' >
                    <a href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard' className='bg-mons-blue-1 hover:text-white hover:bg-monstrade-green px-5 py-3 rounded-lg text-white font-semibold'>Join Us</a>
                </div>
            </div>
        </div>
    </div>
  )
}
