import React from 'react'
//images
import { EBookCard } from '../components/Cards/EBookCard'

export const EBookListContainer = () => {
    const books = [
        {
            h1: "Introduction to Fibonacci Analysis",
            p: "This book opens the doors of income",
            link: "../../assets/documents/introductionToFibonacci.pdf"
        },
        {
            h1: "Knowing Your Trading Costs",
            p: "Helps to win while trading",
            link: "../../assets/documents/tradingCosts.pdf"
        },
        {
            h1: "An In-Depth Look at Risk Management",
            p: "Important for risk-free trading",
            link: "../../assets/documents/riskManagement.pdf"
        },
        {
            h1: "Trading The Majors",
            p: "Your loss is your gain",
            link: "../../assets/documents/tradingMajors.pdf"
        },
        {
            h1: "The Ultimate Guide to Become an IB",
            p: "Platform training",
            link: "../../assets/documents/becomeIB.pdf"
        }
    ]
  return (
    <div className=' bg-mons-blue-1'>
        <div className='container-max mx-auto'>
            <div className='grid lg:grid-cols-3 py-10 gap-4'>
                { books.map( (item) => {
                    return <EBookCard h1={item.h1} p={item.p} link={item.link}/>
                })}
            </div>
        </div>
    </div>
  )
}
