import React from 'react'

export const CompetitiveForexSwapRates = () => {
    return (
        <div className='bg-mons-gray-1 ' >
            <div className='container-max mx-auto py-16'>
                <div className='text-center mb-3'>
                    <h2 className='text-3xl font-medium' >Get Competitive Forex Swap Rates</h2>
                </div>
                <div className='text-center flex mb-6'>
                </div>
                <div className='grid lg:grid-cols-2 -lg:grid-rows-2 sm:text-left text-center'>
                    <div className='sm:pr-12' >
                    <p className='' >At Monstrade we offer the most competitive exchange rates in the industry. This means that when you keep positions open overnight, you don't have to worry about the nightly/renewal fee affecting your earnings.
                                    <br/><br/>
                                    To find out the renewal fee, simply use the handy forex swap rates calculator in MetaTrader 5. All you have to do is select the financial instrument you intend to hold the overnight position, fill in the currency and transaction size details and click "Calculate."</p>
                    </div>
                    <div className='lg:pl-12 -lg:pt-4' >
                        <p className='mb-3' >Monstrade all-in-one FX calculator allows you to calculate all the important
                            parametres of your trade including:
                        </p>
                        <ul className='list-disc mb-3 text-left' >
                            <li className='mb-2' >Pip value</li>
                            <li className='mb-2'>Contract size</li>
                            <li className='mb-2'>Swap rate</li>
                            <li className='mb-2'>Margin and potential gain across all trading instruments.</li>
                        </ul>
                        <p>Streamline your process and manage your strategies. Leave the manual calculations to us.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
