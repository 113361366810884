import React, { useState } from 'react';
import iOSQR from '../../assets/images/meta_ios.png';
import androidQR from '../../assets/images/meta_android.png';

const QRDownloadSection = () => {
  const [platform, setPlatform] = useState('ios');

  const QR_CODES = {
    ios: iOSQR,
    android: androidQR
  };

  return (
    <div className="flex lg:flex-row flex-col items-center justify-center bg-[#F2F2FA] p-6 lg:w-[500px] gap-8 mx-auto rounded-lg">
      <img 
        src={QR_CODES[platform]} 
        alt={`${platform} QR Code`} 
        className="transition-all duration-300 w-[132px]"
      />
      <div>
        <p>Scan to download</p>
        <div className="flex text-center">
          <button 
            onClick={() => setPlatform('ios')}
            className={`p-1 px-2 ml-2 ${platform === 'ios' ? 'border-2 border-black rounded-3xl' : ''}`}
          >
            IOS
          </button>
          <button 
            onClick={() => setPlatform('android')}
            className={`p-1 px-2 ml-2 ${platform === 'android' ? 'border-2 border-black rounded-3xl' : ''}`}
          >
            Android
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRDownloadSection;