import React from 'react'
import img from '../assets/images/low-forex-img.png'
import coinImg1 from '../assets/images/coin-img-1.png'
import coinImg2 from '../assets/images/coin-img-2.png'

export const LowForexSpread = () => {
    return (
        <div className=' sm:block hidden' >
            <div className='container-max mx-auto relative'>
                <div className='absolute right-0 lg:block hidden -top-10' >
                    <img src={coinImg1} alt="" />
                </div>
                <div className='absolute right-0 lg:block hidden -bottom-12' >
                    <img src={coinImg2} alt="" />
                </div>
                <div className='grid grid-cols-2 p-12'>
                    <div>
                        <img src={img} alt="" />
                    </div>
                    <div className='flex' >
                        <div className='m-auto text-left' >
                            <h3 className='text-4xl mb-10' >Industry Leading <br /><span className='font-bold' >Low Forex Spreads</span></h3>
                            <p>
                                Monstrade provides variable spreads with low re-price probability for fast execution. 
                                Our feed comes from leading international financial institutions for deep liquidity 
                                and real-time quotes for different tradable instruments. This allows us to offer forex 
                                spreads as low as 0.0 pips on RAW ECN accounts and 0.8 pips on Standard STP accounts.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

