import React from "react";
import LeverageTable from "../components/Tables/LeverageTables";
import leverageContent from "../features/content/leverage.content"
import paymentImg from "../assets/images/payment-img.png"
const ContentLeverage = () => {
    const content = leverageContent()
    return (
        <>
            <div className="" >
                <div className="container-max mx-auto">
                    {content.map((table, index) => {
                        return (
                            <LeverageTable table={table} key={index} />
                        )
                    })}
                    <div className="text-center" >
                        <div>
                            <p className="text-lg mb-3" >*For USD accounts, equity is calculated at the current exchange rate.</p>
                        </div>
                        <div>
                            <p className="text-lg text-gray-400" >Deposit funds using any of the available payment methods</p>
                        </div>
                        <div className="flex" >
                            <div className="m-auto py-5">
                                <img src={paymentImg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContentLeverage;