import React from 'react'
import TrustedNavbar from '../../assets/images/navbar-trusted.png'

export const SideNav = ({ items }) => {
    return (
        <div className="w-[100vw] h-[100vh] h-full shadow-md bg-white px-1 absolute invisible " id="sidenav">
            <ul className="relative">
                {items.map((item, index) => {
                    return (
                        <li className="relative" id={item.header} key={index}>
                            <a className="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-white hover:bg-mons-blue-1 transition duration-300 ease-in-out cursor-pointer"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseSidenav${index}`}
                                aria-expanded="true"
                                aria-controls={`collapseSidenav${index}`} >
                                <span>{item.header}</span>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" className="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                                </svg>
                            </a>
                            <ul className="relative accordion-collapse collapse"
                                id={`collapseSidenav${index}`}
                                aria-labelledby={item.header}
                                data-bs-parent="#sidenavExample">
                                {item.subItems.length > 0 &&
                                    item.subItems.map((subItem1, subIndex) => {
                                        return (
                                            <div className='px-8 py-2' key={subIndex}>
                                                <span className='text-sm font-bold' >{subItem1.name}</span>
                                                {subItem1.subItems.map((subItem2, subIndex2) => {
                                                    return (
                                                        <li className="relative" key={`${subIndex}${subIndex2}`}>
                                                            <a href={subItem2.link}
                                                                className="flex items-center text-sm py-2  h-6 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"
                                                                data-mdb-ripple="true"
                                                                data-mdb-ripple-color="dark">{subItem2.name}</a>
                                                        </li>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })
                                }
                            </ul>
                        </li>
                    )
                })}
                <li className='relative'>
                    <div className='grid'>
                        <a href='/christmas-draw' className=' text-center mr-2 bg-[#C72121] hover:shadow-md p-2 rounded-[26px] px-3 text-white xl:text-base text-sm w-full'>
                            Join The Draw
                        </a>
                    </div>
                    <div className='grid grid-cols-2 text-center my-5 gap-5 text-white font-bold'>
                        <a className='basis-1/2 bg-monstrade-green rounded p-2' href='https://client.monstradecrm.com/en/auth/login?returnUrl=%2Fdashboard'>Login</a>
                        <a className='basis-1/2 bg-monstrade-blue rounded p-2' href='https://client.monstradecrm.com/en/auth/register?returnUrl=%2Fdashboard'>Register</a>
                        <div className='col-span-2 mx-auto'>
                            <a target='_blank' href="https://www.wikifx.com/tr/dealer/3545945396.html"><img src={TrustedNavbar} alt="" /></a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}
