import React from 'react'
import Banner from '../../assets/images/banners/choose-product-banner.png'

const ChooseProduct = () => {
  return (
    <>
      <div className='bg-monstrade-blue'>
        <div className='flex flex-col lg:flex-row items-center'>
          <div className='text-white p-8 lg:pl-16 flex-1'>
            <p className='text-xl font-semibold'>| MONSTRADE PRIVILEGES</p>
            <p className='text-6xl font-semibold py-28'>Choose Product</p>
            <p className='text-sm max-w-[460px]'>Forex trading involves high risks and may result in the loss of your entire investment. Avoid trading without adequate knowledge.</p>
          </div>
          <div className='w-full lg:w-auto'>
            <img src={Banner} alt="" className='w-full h-full object-cover' />
          </div>
        </div>
      </div>
      <div className='bg-[#F3F5F6] lg:p-16 p-4 text-[#787878] rounded-[2px]'>
        <div className='mx-auto bg-white lg:p-16'>
          <p className='text-3xl font-bold pb-4'>Campaign Details</p>
          <p>
            The investor must request the Choose Product application from an investment advisor or istitutional support lines before benefiting from it.
            <br/>
            <br/>
            Within the scope of the campaign, half of the weekly losses experienced by the investor in the selected product/products are credited to the investor's account as MonsCredit at the end of the week.
            <br/>
            <br/>
            Losses are covered up to 15% of the investor's investment amount, not exceeding CENT category: $250, CLASSIC category: $350, PRIME category: $500 and ECN category: $750.
            <br/>
            <br/>
            Under the Choose Product, losses are reflected in the investor's account only as MonsCredit
            <br/>
            <br/>
            The general rules of the MonsCredit application apply, and it cannot be used as withdrawable capital.
            <br/>
            <br/>
            The application takes place if requested between 20:00-23:00 on Fridays.
            <br/>
            <br/>
            Under the Choose Product, losses in daily opened trades for the selected product are refunded. If there are swaps in the investor's trades, meaning if the trades are carried over to the next day, Choose Product refund is not applicable.
            <br/>
            <br/>
            In case of any misuse, MonsTrade reserves the right to suspend the investor from the application without any further noice.
          </p>
        </div>
      </div>
    </>
  )
}

export default ChooseProduct