import React from 'react'
import { BannerContainer } from '../components/Containers/BannerContainer'
import { ContainerWithBGImage } from '../components/ContainerLayout/ContainerWithBGImage'
import { EBookListContainer } from '../containers/EBookListContainer'
import { WhyChoose } from '../containers/WhyChoose'
//images
import bgImage from '../assets/images/banners/ebook-banner.png'
import bgImage2 from '../assets/images/ebook-bg-image.png'
import { BannerContainerV2 } from '../components/Containers/BannerContainerV2'

export const EBooksPage = () => {

  const landingOptions = {
    image: bgImage,
    imageGradient: "linear-gradient(90deg, rgba(20, 20, 20, 0.4), rgba(0, 0, 0, 0.1))",
    container: {
      width: 'md:w-1/2 w-3/4',
      padding: 'py-20'
    },
    header: {
      text: 'E-Books',
      size: 'text-3xl',
      color: 'text-black',
      align: "md:text-left text-center"
    },
    subHeader: {
      text: "For a more comprehensive guide to the financial markets, Monstrade has a range of free ebooks covering topics like risk management and product.",
      color: 'text-black',
      align: "md:text-left text-center"
    },
    button: {
      bgcolor: "bg-white",
      textcolor: "text-monstrade-blue",
      text: "Join Now",
      padding: "pt-4",
      align: "text-left"
    }
  }

  const landingOptions2 = {
    image: bgImage2,
    imageGradient: "linear-gradient(90deg, rgba(255, 255, 255, 0.9), rgba(0, 0, 0, 0.1))",
    container: {
      width: 'md:w-1/2 w-full',
      padding: 'py-72',
      margin: 'md:m-left',
    },
    header: {
      text: 'Are You Ready to Trade with Monstrade?',
      size: 'text-3xl',
      color: 'text-black',
      align: "sm:text-left text-center"
    },
    subHeader: {
      text: "We make trading easy and accessible. MonsTrade offers trading ready platforms so users have the flexibility to enjoy trading anytime, anywhere and on any device.",
      color: 'text-black',
      align: "sm:text-left text-center"
    },
    button: {
      bgcolor: "bg-white",
      textcolor: "text-black",
      bordercolor: "border-monstrade-blue",
      text: "LIVE ACCOUNT",
      textSize: "lg:text-xl text-normal",
      padding: "pt-8 sm:float-left float-center",
      align: "text-left",
    },
    button2: {
      bgcolor: "bg-white",
      textcolor: "text-black",
      bordercolor: "border-monstrade-blue",
      text: "DEMO ACCOUNT",
      textSize: "lg:text-xl text-normal",
      padding: "pt-8",
      align: "text-left"
    }
  }

  return (
    <div>
      <BannerContainerV2 options={landingOptions} />
      <EBookListContainer />
      <WhyChoose />
      <BannerContainerV2 options={landingOptions2} />
    </div>
  )
}
