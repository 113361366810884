import React from 'react'
import { VideoListContainer } from '../containers/VideoListContainer'
import { BgWhiteBlueButton } from '../components/Buttons/BgWhiteBlueButton'
import bgImage from '../assets/images/documents-img.png'
import { BannerContainerV2 } from '../components/Containers/BannerContainerV2'
export const VideoTutorial = () => {

  const landingOptions = {
    image : bgImage,
    imageGradient: "linear-gradient(90deg, rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.3))",
    container : {
      width : 'w-full',
      padding : 'py-2'
    },
    header: {
      text : 'Videos',
      size: 'text-3xl',
      align: "text-center"
    },
    subHeader: {
        text : "Tutorial videos for beginers",
        align: "text-center"
    },
  }

  return (
    <div>
      <BannerContainerV2 options={landingOptions}/>
      <VideoListContainer />
      <div className=' bg-mons-blue-1'>
        <div className='container-max flex mx-auto'>
          <div className='m-auto flex py-3 hidden' >
            <h3 className='text-white mr-5 text-3xl font-semibold my-auto' >Ready for trading?</h3>
            <BgWhiteBlueButton text={'START NOW'} link={'/'} />
          </div>
        </div>
      </div>
    </div>
  )
}
