import React from 'react'
import { useState } from 'react';

import { CalculatorForm } from '../components/Forms/CalculatorForm';
import { calculationsContent } from '../features/content/calculations.content'

export const Calculators = () => {
    const [active, setActive] = useState(0);
    const content = calculationsContent();

    return (
        <div className=''>
            <div className='container-max mx-auto'>
                <div className='mt-12 mb-20'>
                    <h2 className='text-3xl font-bold text-center' >{content[active].name} Calculator</h2>
                </div>
                <div className='w-2/3 mx-auto mb-20' >
                    <div className='md:grid gap-2 flex grid-cols-5 overflow-x-auto'>
                        {content.map((item, index) => {
                            return <button key={index} className={`${index === active ? 'bg-mons-blue-1 text-white' : 'text-black'} rounded py-4 px-5 font-bold`}
                                onClick={() => { setActive(index) }} >{item.name}
                            </button>
                        })}
                    </div>
                </div>
                <div className='lg:w-2/3 w-full flex mx-auto' >
                        <CalculatorForm content={content[active]}/>
                </div>
            </div>
        </div>
    )
}
