import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const AccountSlider = ({ accounts,tableHeaders, title, desc }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const cardWidth = 25;

    const nextSlide = () => {
        setActiveIndex((prev) => (prev === accounts.length - 1 ? 0 : prev + 1));
    };

    const prevSlide = () => {
        setActiveIndex((prev) => (prev === 0 ? accounts.length - 1 : prev - 1));
    };

 

    return (
        <div className="w-full  bg-gradient-to-b from-[#020202] to-[#001F4F] p-4 py-20 md:p-6 lg:p-20">
            <div className="max-w-7xl mx-auto relative">
                <div className="mb-8 md:mb-12 flex flex-col gap-6 md:gap-12">
                    <h1 className="text-white text-center text-3xl md:text-4xl lg:text-5xl font-light tracking-wide">
                        {title}
                    </h1>
                    <p className="text-white text-center text-sm md:text-base">{desc}</p>
                </div>

                {/* Mobile View */}
                <div className="block lg:hidden">
                    {accounts.map((account, index) => (
                        <div
                            key={index}
                            className={`w-full mb-6 bg-[#D9D9D926] rounded border border-[#676767] overflow-hidden 
                                      ${index !== activeIndex ? 'hidden' : ''}`}
                        >
                            <div className="p-4">
                                <h2 className="text-white text-lg md:text-xl font-normal mb-3">{account.title}</h2>
                                <p className="text-white text-sm leading-relaxed">{account.description}</p>
                            </div>

                            <div className="p-4 space-y-2 text-xs">
                                {tableHeaders.map((header, idx) => (
                                    <div key={idx} className="flex flex-col border-b border-[#676767] pb-2">
                                        <span className="text-blue-300 font-light mb-1">{header}</span>
                                        <span className="text-white font-light">
                                            {account[Object.keys(account)[idx + 2]]}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Desktop View */}
                <div className="hidden lg:flex">
                    {/* Headers Column */}
                    <div className="w-40 flex-shrink-0">
                        <div className="xl:pt-[193px] pt-[216px] space-y-[10px] text-xs mr-2">
                            {tableHeaders.map((header, index) => (
                                <div key={index} className="h-[38px] flex items-center justify-end border-b border-[#676767] pb-2.5">
                                    <span className="text-[#C1C1C1] font-light text-[11px]">{header}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Slider Content */}
                    <div className="flex-1 overflow-hidden">
                        <div
                            className="flex gap-4 transition-transform duration-500 ease-in-out"
                            style={{
                                transform: `translateX(-${activeIndex * cardWidth}%)`
                            }}
                        >
                            {accounts.map((account, index) => (
                                <div
                                    key={index}
                                    className="w-1/4 flex-shrink-0 bg-[#D9D9D926] rounded border border-[#676767] overflow-hidden transition-all duration-300"
                                >
                                    <div className="p-5">
                                        <h2 className="text-white text-xl font-normal mb-3">{account.title}</h2>
                                        <p className="text-white text-sm leading-relaxed">{account.description}</p>
                                    </div>

                                    <div className="p-5 space-y-[10px] text-xs">
                                        {tableHeaders.map((_, idx) => (
                                            <div key={idx} className="flex justify-start border-b border-[#676767] pb-2.5 h-[38px] items-center">
                                                <span className="text-white font-light">
                                                    {account[Object.keys(account)[idx + 2]]}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Navigation Buttons */}
                <div className="absolute -bottom-16 right-0 flex gap-2">
                    <button
                        onClick={prevSlide}
                        className="w-12 h-12 rounded  flex items-center justify-center"
                    >
                        <ChevronLeft className="w-12 h-12 text-white" />
                    </button>
                    <button
                        onClick={nextSlide}
                        className="w-12 h-12 rounded  flex items-center justify-center"
                    >
                        <ChevronRight className="w-12 h-12 text-white" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AccountSlider;