import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import axios from 'axios'
import Image1 from '../assets/images/x-mas-image2.png'

import SilverTicket from '../assets/images/silverTicket.png'
import GoldTicket from '../assets/images/goldenTicket.png'
import DiamondTicket from '../assets/images/diamondTicket.png'
import Apple from '../assets/images/home-app-store.png'
import Google from '../assets/images/home-google-store.png'
import MonstarIco from '../assets/images/x-mas-monstar.png'
import { ToastContainer, toast } from 'react-toastify';
import Pagination from './Pagination';

export const XMasCheckTicket = () => {

    const [isDataReturned, setIsDataReturned] = useState(false)
    const [isDataReturned2, setIsDataReturned2] = useState(false)
    const [isClicked, setIsClicked] = useState('')
    const [isLoaded, setIsLoaded] = useState('')
    const [tickets, setTickets] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)
    const { register, formState: { errors }, handleSubmit } = useForm();

    const [silverCount, setSilverCount] = useState(0)
    const [goldenCount, setGoldenCount] = useState(0)
    const [diamondCount, setDiamondCount] = useState(0)

    const [diamondTickets, setDiamondTickets] = useState(0)
    const [goldenTickets, setGoldenTickets] = useState(0)
    const [silverTickets, setSilverTickets] = useState(0)
    const [userCount, setUserCount] = useState(0)

    const [filteredTickets, setFilteredTickets] = useState([]);
    const [filterType, setFilterType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const totalPages = Math.ceil(filteredTickets.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentTickets = filteredTickets.slice(startIndex, endIndex);

    // Pagination handler
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getTicketByMeta = async (data) => {
        try {
            console.log(data)
            const result = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/getTicketByMeta`, { params: data });
            console.log("result:", result.data.Data.data)
            return { success: true, data: result.data.Data.data };
        } catch (error) {
            console.error(error)
            return { success: false, error: error };
        }
    }

    const getTicketSummary = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/getTicketSummary`);
            setDiamondTickets(result.data.Data.diamond_count);
            setGoldenTickets(result.data.Data.golden_count);
            setSilverTickets(result.data.Data.silver_count);
            setUserCount(result.data.Data.user_count);
        } catch (error) {
            console.error("Failed to fetch ticket summary", error);
            toast.error("Failed to fetch ticket summary.");
        }
    };

    useEffect(() => {
        getTicketSummary();
    }, []);


    const handleDisabled = async () => {
        setIsDisabled(true)
        await setTimeout(() => {
            setIsClicked("bg-green-600");
            setIsLoaded("Checked");
            setIsDisabled(false);
        }, 3000);
        setIsClicked("bg-gray-600")
        setIsLoaded("Loading")
    }

    const ticketCounter = async (tickets) => {
        console.log("test", tickets)

        tickets.map(ticket => {

            console.log(ticket.type, 'ticketmis')

            ticket.type == "SILVER" ? setSilverCount((prevValue) => prevValue + 1) : ticket.type == "GOLDEN" ? setGoldenCount((prevValue) => prevValue + 1) : setDiamondCount((prevValue) => prevValue + 1)

        })

        console.log("Sayılar:", silverCount, goldenCount, diamondCount)

    }

    const onMetaSubmit = async (data) => {
        handleDisabled()
        const result = await getTicketByMeta(data);
        if (result.success) {
            setIsDataReturned(true)
            ticketCounter(result.data)
            setTickets(result.data)

            if (result.data.length === 0) {

                toast("You have no tickets !", {
                    position: toast.POSITION.BOTTOM_LEFT,
                });

                await setTimeout(() => {
                    setIsDataReturned(false);
                    setIsClicked("bg-gray-600");
                    setIsLoaded("Loading");

                }, 1500);
            }
        } else {
            setIsDataReturned(true)
            setIsDataReturned2(true)
            await setTimeout(() => {
                setIsDataReturned2(false);
                setIsClicked("bg-gray-600");
                setIsLoaded("Loading");
                setIsDataReturned(false);
            }, 1500);
            toast("Your ID or password incorrect !", {
                position: toast.POSITION.BOTTOM_LEFT,
            });

        }
    }

    useEffect(() => {
        if (tickets.length > 0) {
            if (filterType === '') {
                setFilteredTickets(tickets);
            } else {
                setFilteredTickets(tickets.filter(ticket => ticket.type === filterType));
            }
        }
    }, [filterType, tickets]);

    // const getTicketImage = (type) => {
    //     switch (type) {
    //         case 'SILVER':
    //             return SilverTicket;
    //         case 'GOLDEN':
    //             return GoldTicket;
    //         case 'DIAMOND':
    //             return DiamondTicket;
    //         default:
    //             return SilverTicket;
    //     }
    // };

    return (
        <>
            <div className='flex lg:flex-row flex-col justify-around items-center lg:text-left text-center py-4 lg:mx-32 mx-2'>
                <div className='lg:col-span-3 py-4 lg:order-first order-last'>
                    <p className='text-[#DE0B0B] text-2xl font-bold'>Check Your Tickets</p>
                    <p className='py-4'>View your tickets by entering your MetaTrader ID number and password.</p>
                    <div className='flex lg:flex-row flex-col gap-4'>
                        <div>
                            <input {...register('username', { required: true })} className='bg-[#E9E9E9] rounded-[37px] p-2 md:w-auto w-full' type="text" placeholder='MetaTrader ID' />
                        </div>
                        <div>
                            <input {...register('password', { required: true })} className='bg-[#E9E9E9] rounded-[37px] p-2 md:w-auto w-full' type="password" placeholder='Password' />
                        </div>
                        <div className='md:inline-block block md:col-span-1 col-span-2'>
                            <button
                                disabled={isDisabled}
                                onClick={handleSubmit(onMetaSubmit)}
                                type='submit'
                                className={`${isDataReturned == false ? "bg-red-600 " : isClicked} rounded-lg p-2 text-white lg:w-auto w-full font-bold`}>
                                {isDataReturned == false ? "Check" : isLoaded}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='lg:col-span-2'>
                    <img className='lg:mx-0 mx-auto lg:order-last order-first w-full' src={Image1} alt="" />
                </div>
            </div>

            {/* isDataReturned == true */}
            {isDataReturned == true && isDataReturned2 == false ?
                <div className='flex lg:flex-row flex-col justify-center gap-[24px] mb-16'>
                    <div className=' py-8'>
                        <div className='flex flex-col gap-2 mx-4 '>
                            <h3 className='text-[#DE0B0B] lg:text-5xl text-3xl font-bold mb-[27px]'>Your Informations</h3>

                            <div className='grid grid-cols-2 gap-[22px] '>
                                <div>
                                    <p className='text-[#C9C9C9] text-sm'>Name</p>
                                    <input className='rounded-lg w-full px-2 py-2 border border-[#FF0006] bg-[#F3F3F3]' type="text" value={tickets[0]?.user.first_name} readOnly />
                                </div>
                                <div>
                                    <p className='text-[#C9C9C9] text-sm'>Surname</p>
                                    <input className='rounded-lg w-full px-2  py-2 border border-[#FF0006] bg-[#F3F3F3]' type="text" value={tickets[0]?.user.last_name} readOnly />
                                </div>
                            </div>
                            <div className='md:col-span-1 col-span-2'>
                                <p className='text-[#C9C9C9] text-sm'>Email</p>
                                <input className='rounded-lg w-full px-2 py-2 border border-[#FF0006] bg-[#F3F3F3]' type="text" value={tickets[0]?.user.email} readOnly />
                            </div>
                            <div className='grid grid-cols-2 gap-[22px] mb-12'>
                                <div>
                                    <p className='text-[#C9C9C9] text-sm'>Phone Number</p>
                                    <input className='rounded-lg w-full px-2  py-2 border border-[#FF0006] bg-[#F3F3F3]' type="text" value={tickets[0]?.user.verificationCode} readOnly />
                                </div>
                                <div>
                                    <p className='text-[#C9C9C9] text-sm'>Meta ID</p>
                                    <input className='rounded-lg w-full px-2  py-2 border border-[#FF0006] bg-[#F3F3F3]' type="text" value={tickets[0]?.user.username} readOnly />
                                </div>
                            </div>
                            <div className='lg:flex hidden flex-col gap-5'>
                                <p className='text-[#8D8D8D]'>Download Monstar App, check all your tickets and see all the prizes..</p>
                                <div className='flex lg:flex-row flex-col gap-[13px]'>
                                    <a href="">
                                        <img className='w-28' src={Apple} alt="" />
                                    </a>
                                    <a href="">
                                        <img className='w-28' src={Google} alt="" />
                                    </a>
                                    <a href="">
                                        <img className='w-28' src={MonstarIco} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='bg-[#F4F4F4] rounded-[28px] py-2 lg:px-8 px-2 lg:mx-0 mx-2 '>
                        <div className='flex flex-col items-start mb-[22px]'>
                            <p className='text-[#DE0B0B] text-3xl font-bold text-center pt-6'>Your Tickets</p>
                            <p>The tickets for the 2025 New Year Lottery you have won are displayed below.</p>
                        </div>
                        {/* filter */}
                        <div className="flex flex-wrap gap-2 items-center max-w-[520px]">
                            <div className="flex-1 min-w-[80px] bg-[#DDDDDD] py-1 rounded-[5px]">
                                <span className="text-[#5C5C5C] text-base font-normal text-center block">
                                    Total: {tickets?.length}
                                </span>
                            </div>

                            <div className="flex-1 min-w-[80px] bg-[#DDDDDD] py-1 rounded-[5px]">
                                <span className="text-[#5C5C5C] text-base font-normal text-center block">
                                    Silver: {silverCount}
                                </span>
                            </div>

                            <div className="flex-1 min-w-[80px] bg-[#DDDDDD] py-1 rounded-[5px]">
                                <span className="text-[#5C5C5C] text-base font-normal text-center block">
                                    Gold: {goldenCount}
                                </span>
                            </div>

                            <div className="flex-1 min-w-[80px] bg-[#DDDDDD] py-1 rounded-[5px]">
                                <span className="text-[#5C5C5C] text-base font-normal text-center block">
                                    Diamond: {diamondCount}
                                </span>
                            </div>

                            <select
                                className="flex-1 min-w-[80px] rounded-[19px] py-1 px-2"
                                value={filterType}
                                onChange={(e) => setFilterType(e.target.value)}
                            >
                                <option value="">All Tickets</option>
                                <option value="SILVER">Silver</option>
                                <option value="GOLDEN">Gold</option>
                                <option value="DIAMOND">Diamond</option>
                            </select>
                        </div>

                        <div className='md:flex grid py-8'>

                            <div className=' mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4'>
                                {/* tickets */}
                                {currentTickets.map((ticket, index) => (
                                    <div
                                        key={index}
                                        className='flex flex-col items-center bg-[#DFDFDF] p-3 rounded-lg'
                                    >
                                        <img
                                            src={`https://draw-app-assets.s3.us-east-1.amazonaws.com/tickets/${ticket?.type.toUpperCase()}.png`}
                                            alt={`${ticket.type} Ticket`}
                                            className='w-[260px] h-auto'
                                        />
                                        <p className='mt-2 text-sm font-medium'>{ticket?.ticket_number}</p>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div >
                </div> : ""}
            {isDataReturned2 == true ?
                ""
                : ""}
            <div className='bg-[#C61414]'>
                <div className='grid lg:grid-cols-4 grid-cols-2 lg:gap-0 gap-2 text-center lg:mx-20 py-6'>
                    <div>
                        <p className='text-white text-3xl font-bold'>+{8219 + userCount}</p>
                        <p className='text-white lg:text-xl text-lg text-center'>PARTICIPANT</p>
                    </div>
                    <div>
                        <p className='text-white text-3xl font-bold'>{12921 + silverTickets}</p>
                        <p className='text-white lg:text-xl text-lg text-center'>SILVER TICKETS</p>
                    </div>
                    <div>
                        <p className='text-white text-3xl font-bold'>{8619 + goldenTickets}</p>
                        <p className='text-white lg:text-xl text-lg text-center'>GOLDEN TICKETS</p>
                    </div>
                    <div>
                        <p className='text-white text-3xl font-bold'>{5397 + diamondTickets}</p>
                        <p className='text-white lg:text-xl text-lg text-center'>DIAMOND TICKETS</p>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
