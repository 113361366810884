import React from 'react'
import img from '../assets/images/spread-img.png'

export const SpreadCalculationInfo = () => {
    return (
        <div className=' sm:block hidden'>
            <div className='container-max mx-auto py-12'>
                <div className='grid grid-cols-2 my-12'>
                    <div>
                        <img src={img} alt="" />
                    </div>
                    <div className='flex'>
                        <div className='my-auto' >
                            <p className='leading-5 font-medium' >
                            For example, if the buy/sell rate for EUR/USD is 1.1251/1.1252. Here, EUR is the base currency and USD is the counter currency. 
                            This means you can buy the EUR at the higher ask price of 1.1252 and sell lower at the bid of 1.1251.<br/><br/>
                            The difference between the buy and sell price is the forex spread. In the example above, the distribution in pips would be 
                            (1.1252-1.1251) = 0.0001. In USD-based parities, the pip value is defined in the 4th digit after the decimal point. This means 
                            that the final forex spread is 0.1 pips.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='grid lg:grid-cols-2 bg-[#F5F5F5] p-10 rounded-xl gap-10'>
                    <div className='px-12 lg:border-r-2 border-[#9F9F9F]'>
                        <p className='mb-8 font-medium ' >
                            To calculate the total spread cost, multiply this pip value by the total
                            number of lots traded. So, if you are trading a EUR/USD trading lot of 10,000
                        </p>
                        <div className='bg-[#424242] rounded-lg p-8 text-center text-white' >
                            <p>
                                your total cost will be <br />
                                0.0001 (0.1pips) x 10000= $0.1
                            </p>
                        </div>
                        <p className='pt-6'>
                            Now, if your account is in another currency, for example GBP, you will need to convert it from US Dollars. 
                            <br/><br/>
                            It is important for Forex traders to be familiar with forex spreads because they represent the primary cost of trading. It is an important parameter to consider when choosing a broker.
                        </p>
                    </div>
                    <div>
                        <p className='mb-8 font-medium ' >
                            To calculate the total spread cost, multiply this pip value by the total
                            number of lots traded. So, if you are trading a EUR/USD trading lot of 10,000
                        </p>
                        <div className='bg-[#424242] rounded-lg p-8 text-center text-white' >
                            <p>
                                your total cost will be <br />
                                0.0001 (0.1pips) x 10000= $0.1
                            </p>
                        </div>
                        <p className='pt-6'>
                            If you choose to open an MonsTrade RAW ECN account and trade, you will find some of the lowest spreads on the market. Our liquidity is provided by some of the largest and best liquidity providers. This gives us the ability to offer you the tightest spreads, starting as low as 0.0 pips at the most liquid times, something that was previously the domain of hedge funds only. You can always check our trading conditions by opening a demo account.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
