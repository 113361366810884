import React from 'react'
import { BannerContainer } from '../components/Containers/BannerContainer'
//images
import bgImage from '../assets/images/banners/accounts-banner.png'
import comonditiesIcon from '../assets/images/comondities-icon.png'
import { SimpleCardListContainer } from '../components/ContainerLayout/SimpleCardListContainer'
import { CompareAccountTypeContainer } from '../containers/CompareAccountTypeContainer'
import { StartTrading } from '../containers/StartTrading'
import { TakingOffContainer } from '../containers/TakingOffContainer'

import icon1 from '../assets/icons/account-big-icon-1.svg';
import icon2 from '../assets/icons/account-big-icon-2.svg';
import icon3 from '../assets/icons/account-big-icon-3.svg';
import icon4 from '../assets/icons/account-big-icon-4.svg';
import icon5 from '../assets/icons/account-big-icon-5.svg';
import icon6 from '../assets/icons/account-big-icon-6.svg';
import { BannerContainerV2 } from '../components/Containers/BannerContainerV2'

export const Accounts = () => {

    const cards = [
        { header: 'Demo Account', description: 'Are you ready for real account', icon: icon1, link: '#DEMO' },
        { header: 'Cent Account', description: 'Be ready to trade for less risk', icon: icon2, link: '#Cent' },
        { header: 'Classic Account', description: 'You are ready to trade', icon: icon3, link: '#Classic' },
        { header: 'Prime Account', description: 'Advantage of the  trade on the best market ', icon: icon4, link: '#Prime' },
        { header: 'ECN Account', description: 'For market professionals on exclusive conditions', icon: icon5, link: '#ECN' },
        { header: 'Islamic Account', description: 'Swap-Free accounts are charged a fixed forex commission', icon: icon6, link: '#Islamic' }
    ]

    const landingOptions = {
        image : bgImage,
        imageGradient : "rgb(3 0 117 / 65%)",
        container : {
          width : 'md:w-1/3 w-full',
          padding : 'py-20',
          margin : 'md:ml-0 m-auto'
        },
        header: {
          text : 'We Collect the Most Accurate News for You.',
          size: 'text-3xl',
          align: 'md:text-left text-center'
        },
        subHeader: {
          text : "We understand that every trader has different needs. Monstrade offers 6 account types to help you trade better.",
          align : "md:text-left text-center"
        },
        button: {
            bgcolor: "bg-white",
            textcolor: "text-monstrade-blue md:ml-0 m-auto",
            text: "Join Now",
            padding: "pt-4 flex"
        }
      }

    return (
        <div>
            <BannerContainerV2 options={landingOptions}/>
            <SimpleCardListContainer 
                cards={cards}
                header={'<b>What Type of Trading Account is Right for You?</b>'}
                text={'Monstrade offers 6 account types to best suit your trading needs.'}
            />
            <CompareAccountTypeContainer />
            <div className='grid md:grid-cols-2 text-white m-auto gap-24  bg-gradient-to-b from-[#2062F4] to-[#0F1A32] py-20'>
                        <div className='my-auto grid justify-self-center' >
                            <h3 className='text-4xl font-semibold md:pt-0 pt-8 md:mb-0 mb-[-30px]' >
                                A Step-by-Step <br/>
                                Guide to Choosing <br/>
                                Your Trading <br/>
                                Account <br/>
                            </h3>
                    </div>
                    <div className='grid grid-flow-row gap-16 pb-10 relative justify-self-start px-4'>
                        <div className='absolute h-full py-20 ml-14'>
                            <div className='border-l-4 border-dashed h-full'></div>
                        </div>
                        <div className='flex z-50 mb-4' >
                            <div className='w-[114px] h-[114px] flex bg-white rounded-full p-8 mr-4'>
                                <div className='text-mons-blue-1 text-center font-bold'>
                                    <p className='text-xl leading-3' >STEP</p>
                                    <p className='text-4xl'>1</p>
                                </div>
                            </div>
                            <div className='flex' >
                                <div className='my-auto' >
                                    <p className='font-bold' >Understand the Products</p>
                                    <p className='text-sm font-light' >Is trading Forex or CFDs appropriate for you? How far does your experience and knowledge go?</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex z-50 mb-4' >
                            <div className='w-[114px] h-[114px] flex bg-white rounded-full p-8 mr-4'>
                                <div className='text-mons-blue-1 text-center font-bold'>
                                    <p className='text-xl leading-3' >STEP</p>
                                    <p className='text-4xl'>2</p>
                                </div>
                            </div>
                            <div className='flex' >
                                <div className='my-auto' >
                                    <p className='font-bold' >Measure Your Risk Level</p>
                                    <p className='text-sm font-light' >
                                        Is trading Forex or CFDs appropriate for you? How far does your 
                                        experience and knowledge go?
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex z-50' >
                            <div className='w-[114px] h-[114px] flex bg-white rounded-full p-8 mr-4'>
                                <div className='text-mons-blue-1 text-center font-bold'>
                                    <p className='text-xl leading-3' >STEP</p>
                                    <p className='text-4xl'>3</p>
                                </div>
                            </div>
                            <div className='flex' >
                                <div className='my-auto' >
                                    <p className='font-bold' >Choose the Right Trading Account and Products</p>
                                    <p className='text-sm font-light' >
                                                                        Consider how much time you can spend on trading. Leaving a position 
                                                                        overnight will incur rollover fees. Setting up an EA will automate 
                                                                        your trades, for which you might require a Virtual Private Server. 
                                                                        Some account types offer this for free!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <TakingOffContainer />
            <StartTrading />
        </div>
    )
}
